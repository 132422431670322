import { useCallback, useContext } from "react";

import { BaseModal } from "./BaseModal";
import { PlayerContext } from "../contexts/PlayerContext";
import { PlatformContext } from "../contexts/PlatformContext";
import { HouseContext } from "../contexts/HouseContext";
import { TermsAndConditionsStep } from "./registration/TermsAndConditions";

interface ITermsAndConditionsModal {
  open: boolean;
  handleClose: Function;
}

export const TermsAndConditionsModal = ({
  open,
  handleClose,
}: ITermsAndConditionsModal) => {
  const { playerMeta, setPlayerMeta } = useContext(PlayerContext);
  const { platform } = useContext(PlatformContext);
  const { house } = useContext(HouseContext);

  const setLatestTerms = useCallback(() => {
    if (house == null || platform == null) {
      return;
    }

    const meta = playerMeta || {};
    meta.latestHouseTerms = house.latestTermsVersion;
    meta.latestPlatformTerms = platform.latestTermsVersion || 0;
    meta.signedTerms = true;

    setPlayerMeta(meta);
    handleClose();
  }, [playerMeta, platform, house, handleClose]);

  return (
    <BaseModal open={open} hideModal={handleClose}>
      <div className="flex flex-col w-[80vw] md:w-[560px]">
        <TermsAndConditionsStep
          setLatestTermsVersion={setLatestTerms}
          onClose={handleClose}
        />
      </div>
    </BaseModal>
  );
};
