import React, { FC, useMemo } from "react";
import "./zb-icons-codes.css";
import { iconsNames } from "./iconNames";

export type IconName = (typeof iconsNames)[number];
export type IconSizes = "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";
export interface IconProps {
  name: IconName;
  size?: IconSizes;
  className?: string;
  weight?: string;
}

const IconFont: FC<IconProps> = ({ name, size, className, weight }) => {
  const iconSize = useMemo(() => {
    switch (size) {
      case "xxxl":
        return "text-[86px]";
      case "xxl":
        return "text-[30px]";
      case "xl":
        return "text-[20px]";
      case "lg":
        return "text-[18px]";
      case "md":
        return "text-[16px]";
      case "sm":
        return "text-[14px]";
      case "xs":
        return "text-[12px]";
      default:
        return "text-[14px]";
    }
  }, [size]);

  const weightClasses = useMemo(() => {
    switch (weight) {
      case "bold":
        return "before:font-bold";
      default:
        return "before:font-normal";
    }
  }, [weight]);

  return (
    <div
      className={`zb-icon icon-${name} flex items-center justify-center text-current
      ${iconSize} ${className}`}
    />
  );
};

export default IconFont;
