// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --top-bar-height: 62px;
  --top-banner-height: 66px;
  --side-bar-width: 280px;
  --side-bar-width-wide: 304px;

  --side-bar-collapsed-width: 64px;

  --game-frame-tool-bar-height: 52px;
  --modal-header-height: 70px;
  --toastify-toast-width: 340px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/variables.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,uBAAuB;EACvB,4BAA4B;;EAE5B,gCAAgC;;EAEhC,kCAAkC;EAClC,2BAA2B;EAC3B,6BAA6B;AAC/B","sourcesContent":[":root {\n  --top-bar-height: 62px;\n  --top-banner-height: 66px;\n  --side-bar-width: 280px;\n  --side-bar-width-wide: 304px;\n\n  --side-bar-collapsed-width: 64px;\n\n  --game-frame-tool-bar-height: 52px;\n  --modal-header-height: 70px;\n  --toastify-toast-width: 340px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
