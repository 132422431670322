const DAYS = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

export const toDayString = (date: Date) => {
  return DAYS[date.getDay()];
};

export const formatDateToDayAndMonth = (inputDate: Date) => {
  const date = new Date(inputDate);

  const day = date.getDate();
  let month = date.getMonth() + 1;

  month = month < 10 ? "0" + month : month;

  return day + "/" + month;
};

const getTimezoneWithOffset = (date: Date) => {
  const offsetHours = date.getTimezoneOffset() / 60;
  const roundedHours =
    offsetHours % 1 > 0
      ? Math.abs(offsetHours).toFixed(2)
      : Math.abs(offsetHours);

  return `UTC${offsetHours > 0 ? "-" : "+"}${roundedHours}`;
};

export const formatSelfExclusionDate = (date: Date) => {
  // WANT TO RETURN IN FORMAT 12:00 UTC 23/01/2024
  return `${date.getHours()}:${date.getMinutes()} ${getTimezoneWithOffset(
    date,
  )} ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};
