import { createContext, useMemo } from "react";
import { useCasinoErrorHandling } from "../hooks/validation/useCasinoErrorHandling";
import { ErrorType, IGameError } from "../types/error";

export interface IValidation {
  errorMessages: IGameError[];
  addErrorMessage: (error: IGameError) => void;
  removeErrorMessage: (type: ErrorType) => void;
  errorMessageByType: Map<ErrorType, IGameError>;
}

export interface IErrorHandling {
  networkValidation: IValidation;
  houseValidation: IValidation;
  platformValidation: IValidation;
  walletValidation: IValidation;
  gameValidation: IValidation;
  locationValidation: IValidation;
  playerValidation: IValidation;
  hasErrors: boolean;
}

export const ErrorHandlingContext = createContext<IErrorHandling>(
  {} as IErrorHandling,
);

interface Props {
  children: any;
}

export const ErrorHandlingProvider = (props: Props) => {
  const {
    networkValidation,
    houseValidation,
    platformValidation,
    walletValidation,
    gameValidation,
    locationValidation,
    playerValidation,
  } = useCasinoErrorHandling();

  const hasErrors = useMemo(() => {
    return (
      networkValidation.errorMessages.length > 0 ||
      houseValidation.errorMessages.length > 0 ||
      platformValidation.errorMessages.length > 0 ||
      walletValidation.errorMessages.length > 0 ||
      gameValidation.errorMessages.length > 0 ||
      locationValidation.errorMessages.length > 0 ||
      playerValidation.errorMessages.length > 0
    );
  }, [
    networkValidation,
    houseValidation,
    platformValidation,
    walletValidation,
    gameValidation,
    locationValidation,
    playerValidation,
  ]);

  return (
    <ErrorHandlingContext.Provider
      value={useMemo(
        () => ({
          networkValidation: networkValidation,
          platformValidation: platformValidation,
          houseValidation: houseValidation,
          walletValidation: walletValidation,
          gameValidation: gameValidation,
          locationValidation: locationValidation,
          playerValidation: playerValidation,
          hasErrors: hasErrors,
        }),
        [
          networkValidation,
          platformValidation,
          houseValidation,
          walletValidation,
          gameValidation,
          hasErrors,
          locationValidation,
          playerValidation,
        ],
      )}
    >
      {props.children}
    </ErrorHandlingContext.Provider>
  );
};
