import { twMerge } from "tailwind-merge";

interface ITitleAndSubTitle {
  title: string;
  subtitle?: string;
  className?: string;
}

export const TitleAndSubTitle = ({
  title,
  subtitle,
  className,
}: ITitleAndSubTitle) => {
  return (
    <div
      className={twMerge(
        "flex pt-4 flex-col justify-center items-center gap-y-1.5 self-stretch text-center",
        className,
      )}
    >
      <div className="text-2xl font-semibold">{title}</div>
      {subtitle ? (
        <div className="text-sm font-normal text-gray-50">{subtitle}</div>
      ) : (
        ""
      )}
    </div>
  );
};
