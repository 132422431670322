import * as anchor from "@coral-xyz/anchor";
import { Program } from "@coral-xyz/anchor";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";

import { TokenStatus, TokenType } from "./enums";
import { toHouseStatus, toTokenStatus } from "./utils";

export interface IHouseToken {
  pubkey: string;
  availableTradingBalance: number;
  status: TokenStatus;
  statusString: string;
  decimals: number;
  minBetUnit: number;
  incrementUnit: number;
  openLiabilityBalance: number;
  type: TokenType;
  mint: PublicKey;
  pastBetCount: number;
  pastPaidOut: number;
  pastWagered: number;
  pastPnl: number;
  gas: number;
  fxRatePerBillion: number;
  fxRateLastUpdate: number;
  lpExpectedRevenue: number;
  outstandingLpTokens: number;
  gasReclaimBalance: number;
  nftStakerRevenueShareBalance: number;
  platformRevenueShareBalance: number;
  platformRewardAccrualBalance: number;
  platformRewardPayoutBalance: number;
  protocolRevenueShareBalance: number;
  tokenStakerRevenueShareBalance: number;
}

const toHouseTokenType = (tokenType: object): TokenType => {
  if ("baseTradingCurrency" in tokenType) {
    return TokenType.BaseTradingCurrency;
  } else if ("otherTradingCurrency" in tokenType) {
    return TokenType.OtherTradingCurrency;
  } else if ("wrappedSolTradingCurrency" in tokenType) {
    return TokenType.WrappedSolTradingCurrency;
  } else if ("otherWrappedTradingCurrency" in tokenType) {
    return TokenType.OtherWrappedTradingCurrency;
  } else if ("playTradingCurrency" in tokenType) {
    return TokenType.PlayTradingCurrency;
  } else if ("projectTokenTradingCurrency" in tokenType) {
    return TokenType.ProjectTokenTradingCurrency;
  } else if ("projectTokenNonTradingCurrency" in tokenType) {
    return TokenType.ProjectTokenNonTradingCurrency;
  } else {
    throw new Error(`Token type not recognised: ${tokenType}`);
  }
};

export default class House {
  private _program: Program;
  private _housePubkey: PublicKey;
  private _state: any;
  private _eventParser: anchor.EventParser;
  private _houseTokens: Map<string, any>;

  constructor(cashierProgram: anchor.Program, housePubkey: PublicKey) {
    this._program = cashierProgram;
    this._eventParser = new anchor.EventParser(
      this.program.programId,
      new anchor.BorshCoder(this.program.idl),
    );
    this._housePubkey = housePubkey;
    this._houseTokens = new Map();
  }

  static async load(
    cashierProgram: anchor.Program,
    housePubkey: PublicKey,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    const house = new House(cashierProgram, housePubkey);
    await house.loadState(commitmentLevel);
    return house;
  }

  async loadState(commitmentLevel: anchor.web3.Commitment = "processed") {
    const state = await this.program.account.house.fetchNullable(
      this._housePubkey,
      commitmentLevel,
    );
    if (state) {
      this._state = state;
      await this.loadHouseTokenStates();
    } else {
      throw new Error(
        `A valid house account was not found at the pubkey provided: ${this._housePubkey}`,
      );
    }
    return;
  }

  async loadHouseTokenStates(
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    const houseTokenAccountPubkeys = this.listHouseTokenAccountPubkeys;
    const tokenMintPubkeys = this.listTokenMints;
    if (houseTokenAccountPubkeys.length) {
      const houseTokenStates =
        await this.program.account.houseToken.fetchMultiple(
          houseTokenAccountPubkeys,
          commitmentLevel,
        );
      this._houseTokens = new Map();
      for (let i = 0; i < houseTokenStates.length; i++) {
        this._houseTokens.set(
          tokenMintPubkeys[i].toString(),
          houseTokenStates[i],
        );
      }
    } else {
      this._houseTokens = new Map();
    }
  }

  static deriveHousePubkey(houseId: number, programId: PublicKey): PublicKey {
    const [housePubkey, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("house"),
        new anchor.BN(houseId).toArrayLike(Buffer, "le", 8),
      ],
      programId,
    );
    return housePubkey;
  }

  static derivePlatformPubkey(
    housePubkey: PublicKey,
    platformId: number,
    programId: PublicKey,
  ): PublicKey {
    const [platformPubkey, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("platform"),
        housePubkey.toBuffer(),
        new anchor.BN(platformId).toArrayLike(Buffer, "le", 8),
      ],
      programId,
    );
    return platformPubkey;
  }

  derivePlatformPubkey(platformId: number): PublicKey {
    const [platformPubkey, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("platform"),
        this.publicKey.toBuffer(),
        new anchor.BN(platformId).toArrayLike(Buffer, "le", 8),
      ],
      this.program.programId,
    );
    return platformPubkey;
  }

  static deriveHousePermissionAccountPubkey(
    ownerPubkey: PublicKey,
    housePubkey: PublicKey,
    programId: PublicKey,
  ): PublicKey {
    const [permissionAccountPubkey, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("house_permission"),
        housePubkey.toBuffer(),
        ownerPubkey.toBuffer(),
      ],
      programId,
    );
    return permissionAccountPubkey;
  }

  deriveHousePermissionAccountPubkey(ownerPubkey: PublicKey): PublicKey {
    return House.deriveHousePermissionAccountPubkey(
      ownerPubkey,
      this.publicKey,
      this.programId,
    );
  }

  deriveLiquidityProviderAccountPubkey(
    ownerPubkey: PublicKey,
    tokenMintPubkey: PublicKey,
  ): PublicKey {
    const [liquidityProviderAccountPubkey, _] =
      PublicKey.findProgramAddressSync(
        [
          anchor.utils.bytes.utf8.encode("liquidity_provider"),
          this.publicKey.toBuffer(),
          tokenMintPubkey.toBuffer(),
          ownerPubkey.toBuffer(),
        ],
        this.programId,
      );
    return liquidityProviderAccountPubkey;
  }

  async deriveHouseTokenVault(tokenMintPubkey: PublicKey): Promise<PublicKey> {
    return await getAssociatedTokenAddress(
      tokenMintPubkey,
      this.publicKey,
      true,
    );
  }

  deriveHouseTokenAccountPubkey(tokenMintPubkey: PublicKey): PublicKey {
    const [pubkey, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("house_token"),
        this.publicKey.toBuffer(),
        tokenMintPubkey.toBuffer(),
      ],
      this.programId,
    );
    return pubkey;
  }

  async derivePlayerAssociatedTokenAccount(
    ownerPubkey: PublicKey,
    tokenMintPubkey: PublicKey,
  ): Promise<PublicKey> {
    return await getAssociatedTokenAddress(tokenMintPubkey, ownerPubkey, false);
  }

  get program() {
    return this._program;
  }

  get eventParser() {
    return this._eventParser;
  }

  get programId() {
    return this._program.programId;
  }

  get publicKey() {
    return this._housePubkey;
  }

  get connection() {
    return this._program.provider.connection;
  }

  get state() {
    return this._state;
  }

  get id() {
    return this._state ? Number(this._state.id) : null;
  }

  get status() {
    return this._state ? toHouseStatus(this._state.status) : null;
  }

  get liquidityProvisionIsPermissioned() {
    return this._state ? this._state.liquidityProvisionIsPermissioned : null;
  }

  get numActiveCallingPrograms() {
    return this._state ? this._state.numActiveCallingPrograms : null;
  }

  get numActiveTokens() {
    return this._state ? this._state.numActiveTokens : null;
  }

  get pastWagered() {
    return this._state ? Number(this._state.pastWagered) : null;
  }

  get pastPaidOut() {
    return this._state ? Number(this._state.pastPaidOut) : null;
  }

  get pastBetCount() {
    return this._state ? Number(this._state.pastBetCount) : null;
  }

  get listTokenMints(): PublicKey[] {
    return this._state ? this._state.tokens.map((tkn) => tkn.pubkey) : [];
  }

  get listTokenSummaries(): any[] {
    return this._state ? this._state.tokens : [];
  }

  get tokens(): IHouseToken[] {
    return this._houseTokens
      ? Array.from(this._houseTokens.values()).map((token) => {
          return {
            mint: token.tokenMint,
            pubkey: token.tokenMint.toString(),
            availableTradingBalance: Number(token.availableTradingBalance),
            status: toTokenStatus(token.status),
            statusString: Object.keys(token.status)[0],
            decimals: token.decimals,
            minBetUnit: Number(token.minBetUnit),
            incrementUnit: Number(token.incrementUnit),
            openLiabilityBalance: Number(token.openLiabilityBalance),
            type: toHouseTokenType(token.tokenType),
            fxRateToBase: this.getTokenFxRate(token.tokenMint),
            pastBetCount: token.pastBetCount.toNumber(),
            pastPaidOut: token.pastPaidOut.toNumber(),
            pastWagered: token.pastWagered.toNumber(),
            pastPnl:
              token.pastWagered.toNumber() - token.pastPaidOut.toNumber(),
            gas: token.unitGasCost.toNumber(),
            fxRatePerBillion: token.fxRatePerBillion.toNumber(),
            fxRateLastUpdate: token.fxRateLastUpdate.toNumber(),
            outstandingLpTokens: Number(token.outstandingLpTokens),
            lpExpectedRevenue: Number(token.lpExpectedRevenue),
            gasReclaimBalance: Number(token.gasReclaimBalance),
            nftStakerRevenueShareBalance: Number(
              token.nftStakerRevenueShareBalance,
            ),
            platformRevenueShareBalance: Number(
              token.platformRevenueShareBalance,
            ),
            platformRewardAccrualBalance: Number(
              token.platformRewardAccrualBalance,
            ),
            platformRewardPayoutBalance: Number(
              token.platformRewardPayoutBalance,
            ),
            protocolRevenueShareBalance: Number(
              token.protocolRevenueShareBalance,
            ),
            tokenStakerRevenueShareBalance: Number(
              token.tokenStakerRevenueShareBalance,
            ),
          };
        })
      : [];
  }

  getTokenFxRateFromIdx(houseTokenIdx: number): number | null {
    const pk = this.getTokenPubkeyFromHouseTokenIdx(houseTokenIdx);
    if (!pk) {
      return null;
    }
    return this.getTokenFxRate(pk);
  }

  getTokenFxRate(tokenMintPubkey: PublicKey): number | null {
    if (this._state) {
      const baseTokenConfig = this.getBaseTokenConfigAndStatistics();
      const targetTokenConfig =
        this.getTokenConfigAndStatistics(tokenMintPubkey);

      if (baseTokenConfig && targetTokenConfig) {
        if (
          baseTokenConfig.tokenMint.toString() ==
          targetTokenConfig.tokenMint.toString()
        ) {
          return 1.0;
        } else {
          return (
            Number(targetTokenConfig.fxRatePerBillion) /
            10 ** (baseTokenConfig.decimals - targetTokenConfig.decimals + 9)
          );
        }
      }
    }
    return null;
  }

  get listHouseTokenAccountPubkeys(): PublicKey[] {
    return this._state
      ? this._state.tokens.map((tkn) =>
          this.deriveHouseTokenAccountPubkey(tkn.pubkey),
        )
      : [];
  }

  get listCallingPrograms(): PublicKey[] {
    return this._state ? this._state.callingPrograms : [];
  }

  get latestTermsVersion() {
    return this._state ? this._state.latestTermsVersion : null;
  }

  get protocolSplitPerMillion() {
    return this._state ? this._state.protocolSplitPerMillion.toNumber() : null;
  }

  get playerCount() {
    return this._state ? this._state.playerCount.toNumber() : null;
  }

  get platformRewardsClaimedOrForfeitBase() {
    return this._state
      ? this._state.platformRewardsClaimedOrForfeitBase.toNumber()
      : null;
  }

  get platformRewardsAccruedBase() {
    return this._state
      ? this._state.platformRewardsAccruedBase.toNumber()
      : null;
  }

  get platformCount() {
    return this._state ? this._state.platformCount.toNumber() : null;
  }

  get lpProtocolMinimumSharePerMillion() {
    return this._state
      ? this._state.lpProtocolMinimumSharePerMillion.toNumber()
      : null;
  }

  getHouseTokenIdxFromTokenMintPubkey(tokenMintPubkey: PublicKey): number {
    var i: number = 0;
    this.listTokenMints.forEach((tknPubkey) => {
      if (tknPubkey == tokenMintPubkey) {
        return i;
      } else {
        i++;
      }
    });
    return null;
  }

  getTokenPubkeyFromHouseTokenIdx(houseTokenIdx: number): PublicKey {
    if (this._state && this.listTokenMints.length >= houseTokenIdx) {
      return this.listTokenMints[houseTokenIdx];
    }
    return null;
  }

  getTokenConfigAndStatistics(tokenMintPubkey: PublicKey): any | null {
    if (this._houseTokens.size && tokenMintPubkey != null) {
      return this._houseTokens.get(tokenMintPubkey.toString());
    }

    return null;
  }

  getBaseTokenConfigPubkey(): PublicKey | null {
    if (this._state) {
      return this._state.tokens.find((tkn) => {
        const tokenType = toHouseTokenType(tkn.tokenType);

        return tokenType == TokenType.BaseTradingCurrency;
      })?.pubkey;
    }
    return null;
  }

  getProjectTokenPubkey() {
    if (this._state) {
      return this._state.tokens.find((tkn) => {
        tkn.tokenType == TokenType.ProjectTokenTradingCurrency ||
          tkn.tokenType == TokenType.ProjectTokenNonTradingCurrency;
      })?.pubkey;
    }
    return null;
  }

  getBaseTokenConfigAndStatistics() {
    const basePubkey = this.getBaseTokenConfigPubkey();

    if (basePubkey != null) {
      return this.getTokenConfigAndStatistics(basePubkey);
    }

    return null;
  }
  getProjectTokenConfigAndStatistics() {
    if (this.getProjectTokenPubkey()) {
      return this.getTokenConfigAndStatistics(this.getProjectTokenPubkey());
    }
  }

  approximateTokenAmountToBase(
    tokenMintPubkey: PublicKey,
    tokenAmount: number,
  ): number {
    const tokenConfig = this.getTokenConfigAndStatistics(tokenMintPubkey);

    if (tokenConfig) {
      return (
        (tokenAmount * Number(tokenConfig.fxRatePerBillion)) / 1_000_000_000
      );
    } else {
      throw new Error("Token Config not found for this mint");
    }
  }

  approximateTokenAmountToBaseUI(
    tokenMintPubkey: PublicKey,
    tokenAmountBasis: number,
  ): number {
    const baseAmount = this.approximateTokenAmountToBase(
      tokenMintPubkey,
      tokenAmountBasis,
    );

    return baseAmount / Math.pow(10, 6);
  }

  getTokenDecimalsFromHouseTokenIdx(houseTokenIdx: number): number {
    if (this._state && this.listTokenMints.length >= houseTokenIdx) {
      return this.tokens[houseTokenIdx]?.decimals;
    }

    return null;
  }

  getTokenDecimalsFromHouseTokenPubkey(tokenMintPubkey: PublicKey): number {
    if (this._state != null) {
      const tokenMint = tokenMintPubkey?.toString();

      const token = this._state.tokens.find((tkn) => {
        return tkn.pubkey.toString() == tokenMint;
      });

      return token?.decimals;
    }

    return null;
  }

  approximateBaseAmountToToken(
    tokenMintPubkey: PublicKey,
    baseAmount: number,
  ): number {
    const tokenConfig = this.getTokenConfigAndStatistics(tokenMintPubkey);
    if (tokenConfig) {
      if (Number(tokenConfig.fxRatePerBillion) != 0) {
        return (
          baseAmount /
          Number(tokenConfig.fxRatePerBillion.div(new anchor.BN(1_000_000_000)))
        );
      } else {
        return 0;
      }
    } else {
      throw new Error("Token Config not found for this mint");
    }
  }
}

export class HousePermission {
  private _house: House;
  private _publicKey: PublicKey;
  private _state: any;

  constructor(house: House, publicKey: PublicKey, accountBuffer: Buffer) {
    this._house = house;
    this._publicKey = publicKey;
    this._state = house.program.coder.accounts.decode(
      "HousePermission",
      accountBuffer,
    );
  }

  get publicKey() {
    return this._publicKey;
  }

  get house() {
    return this._house;
  }

  get state() {
    return this._state;
  }

  get status() {
    return this._state ? Object.keys(this._state.status)[0] : null;
  }
}
