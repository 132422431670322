import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useErrorMessageValidation } from "../hooks/validation/useErrorMessageValidation";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { HealthContext } from "./HealthContext";
import { ErrorType, IGameError } from "../types/error";
import WarningIcon from "./../assets/icons/warning-circle.svg";
import ErrorIcon from "./../assets/icons/error-circle.svg";
import ClockIcon from "./../assets/icons/clock.svg";
import Icon from "./../components/common/icon/Icon";
import Button from "../components/common/button/Button";
import { useModal } from "react-modal-hook";
import { TermsAndConditionsModal } from "../modals/TermsAndConditionsModal";
import { GeoblockModal } from "../modals/GeoblockModal";

export interface IBannerContext {
  warnings: IGameError[];
  alerts: any[];
}

export const BannerContext = createContext<IBannerContext>(
  {} as IBannerContext,
);

interface Props {
  children: any;
}

export const BannerProvider = ({ children }: Props) => {
  const warnings = useErrorMessageValidation();

  // TODO - LOAD ALERTS FROM SOMEWHERE
  const [alerts, setAlerts] = useState<any[]>([]);

  const { networkValidation, locationValidation, playerValidation } =
    useContext(ErrorHandlingContext);
  const { solanaTps } = useContext(HealthContext);

  useEffect(() => {
    // CHECK INTERNET DOWN
    if (networkValidation.errorMessageByType.has(ErrorType.NO_INTERNET)) {
      warnings.addErrorMessage({
        type: ErrorType.NO_INTERNET,
        title: "",
        message: (
          <Fragment>
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<WarningIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              You are not connected to the internet, or experiencing network
              issues.
            </div>
          </Fragment>
        ),
      });
    } else if (warnings.errorMessageByType.has(ErrorType.NO_INTERNET)) {
      warnings.removeErrorMessage(ErrorType.NO_INTERNET);
    }

    // CHECK SOLANA DOWN
    if (networkValidation.errorMessageByType.has(ErrorType.SOLANA_DOWN)) {
      warnings.addErrorMessage({
        type: ErrorType.SOLANA_DOWN,
        title: "",
        message: (
          <Fragment>
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<WarningIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              The Solana network may be experiencing outage issues.
            </div>
          </Fragment>
        ),
      });
    } else if (warnings.errorMessageByType.has(ErrorType.SOLANA_DOWN)) {
      warnings.removeErrorMessage(ErrorType.SOLANA_DOWN);
    }

    // CHECK SOLANA SLOW
    if (
      warnings.errorMessageByType.has(ErrorType.SOLANA_SLOW) == false &&
      solanaTps != null &&
      solanaTps < 25
    ) {
      warnings.addErrorMessage({
        type: ErrorType.SOLANA_SLOW,
        title: "",
        message: (
          <Fragment>
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<WarningIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              {`The Solana network TPS is lower than usual, this may cause latency or outage issues.`}
            </div>
          </Fragment>
        ),
      });
    } else if (warnings.errorMessageByType.has(ErrorType.SOLANA_SLOW)) {
      warnings.removeErrorMessage(ErrorType.SOLANA_SLOW);
    }
  }, [networkValidation, solanaTps]);

  const [showGeoblockModal, hideGeoblockModal] = useModal(
    ({ in: open }) => (
      <GeoblockModal
        open={open}
        hideModal={() => {
          hideGeoblockModal();
        }}
      />
    ),
    [],
  );

  useEffect(() => {
    // CHECK NOT IN RESTRICTED IP
    if (locationValidation.errorMessageByType.has(ErrorType.IP_RESTRICTED)) {
      warnings.addErrorMessage({
        type: ErrorType.IP_RESTRICTED,
        title: "",
        message: (
          <div
            className="flex gap-x-2 cursor-pointer"
            onClick={showGeoblockModal}
          >
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<ErrorIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              Access Restricted due to location.
            </div>
          </div>
        ),
      });
    } else if (warnings.errorMessageByType.has(ErrorType.IP_RESTRICTED)) {
      warnings.removeErrorMessage(ErrorType.IP_RESTRICTED);
    }
  }, [locationValidation]);

  // USE EFFECT TO CHECK FOR WARNINGS -
  //   TIME_OUT_ACTIVE = 'Time out',
  //   SELF_EXCLUSION_ACTIVE = 'Self exclusion',
  //   TERMS_AND_CONDITIONS_UNSIGNED = 'Terms and conditions'
  const [showTermsModal, hideTermsModal] = useModal(
    ({ in: open }): JSX.Element => (
      <TermsAndConditionsModal open={open} handleClose={hideTermsModal} />
    ),
    [],
  );

  useEffect(() => {
    if (playerValidation.errorMessageByType.has(ErrorType.TIME_OUT_ACTIVE)) {
      const error = playerValidation.errorMessageByType.get(
        ErrorType.TIME_OUT_ACTIVE,
      );
      warnings.addErrorMessage({
        type: ErrorType.TIME_OUT_ACTIVE,
        title: "",
        message: (
          <Fragment>
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<ClockIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              {error?.message}
            </div>
          </Fragment>
        ),
      });
    } else {
      warnings.removeErrorMessage(ErrorType.TIME_OUT_ACTIVE);
    }

    if (
      playerValidation.errorMessageByType.has(ErrorType.SELF_EXCLUSION_ACTIVE)
    ) {
      warnings.addErrorMessage({
        type: ErrorType.SELF_EXCLUSION_ACTIVE,
        title: "",
        message: (
          <Fragment>
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<ErrorIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              Betting is restricted due to Self Exclusion
            </div>
          </Fragment>
        ),
      });
    } else {
      warnings.removeErrorMessage(ErrorType.SELF_EXCLUSION_ACTIVE);
    }

    if (
      playerValidation.errorMessageByType.has(
        ErrorType.TERMS_AND_CONDITIONS_UNSIGNED,
      )
    ) {
      warnings.addErrorMessage({
        type: ErrorType.TERMS_AND_CONDITIONS_UNSIGNED,
        title: "",
        message: (
          <Fragment>
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<WarningIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              Please accepts Terms & Conditions to enable betting
            </div>
            <Button
              variant="secondary-gray"
              size="sm"
              onClick={() => {
                showTermsModal();
              }}
            >
              Terms & Conditions
            </Button>
          </Fragment>
        ),
      });
    } else {
      warnings.removeErrorMessage(ErrorType.TERMS_AND_CONDITIONS_UNSIGNED);
    }
  }, [playerValidation]);

  return (
    <BannerContext.Provider
      value={useMemo(
        () => ({
          warnings: warnings.errorMessages,
          alerts: alerts,
        }),
        [warnings, alerts],
      )}
    >
      {children}
    </BannerContext.Provider>
  );
};
