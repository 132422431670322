import { twMerge } from "tailwind-merge";

import {
  CheckBox,
  CheckboxSize,
} from "../../../components/common/check-box/check-box";

interface ITerm {
  text: string;
  accepted: boolean;
  setAccepted: Function;
  className?: string;
}

export const Term = ({ text, accepted, setAccepted, className }: ITerm) => {
  return (
    <div
      className={twMerge(
        "flex items-start self-stretch text-gray-200",
        className || "",
      )}
    >
      <label className="flex gap-x-3">
        <div className="self-center">
          <CheckBox
            size={CheckboxSize.sm}
            checked={accepted}
            setChecked={setAccepted}
          />
        </div>

        <div className="font-normal text-sm">{text}</div>
      </label>
    </div>
  );
};
