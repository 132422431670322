import React from "react";
import { twMerge } from "tailwind-merge";

import Table from "../../components/common/table/Table";

export const GamePagePreloader = ({}) => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full gap-x-3">
        <div
          className={twMerge(
            "flex bg-animated-gradient-gray-700-gray-800 h-auto w-full relative",
            "mx-auto aspect-video max-w-7xl rounded-lg md:max-h-[calc(100%-var(--game-frame-tool-bar-height))]",
            "max-md:m-0 max-md:flex max-md:aspect-auto max-md:flex-col max-md:overflow-y-visible",
          )}
        >
          <div
            className={twMerge(
              "absolute bottom-0 left-0 right-0 z-30 flex h-[var(--game-frame-tool-bar-height)]",
              "flex-col gap-2 bg-gray-700 p-2 max-md:static rounded-b-lg",
            )}
          />
        </div>
      </div>
      <div className="flex w-full gap-x-3 mt-7">
        <div
          className={twMerge(
            "flex bg-gray-800 w-full p-5 flex-col mx-auto max-w-7xl rounded-lg",
            "max-md:m-0 max-md:flex max-md:aspect-auto max-md:flex-col max-md:overflow-y-visible max-md:rounded-none",
          )}
        >
          <div className="flex w-full justify-between h-8">
            <div className="flex bg-animated-gradient-gray-700-gray-800 h-auto w-24 rounded-md" />
            <div className="flex bg-animated-gradient-gray-700-gray-800 h-auto w-8 rounded-md" />
          </div>
          <div className="flex w-full justify-between mt-7">
            <div className="flex bg-animated-gradient-gray-700-gray-800 aspect-[4/5.5] w-52 rounded-lg mr-5" />
            <div className="flex w-full flex-col h-full gap-y-2">
              <div className="flex w-full h-8 gap-x-2">
                {[1, 2, 3].map((item) => (
                  <div
                    key={item}
                    className="flex bg-animated-gradient-gray-700-gray-800 h-auto w-24 rounded-md"
                  />
                ))}
              </div>
              {[1, 2, 3].map((item) => (
                <div
                  key={item}
                  className={`flex h-3 bg-animated-gradient-gray-700-gray-800 w-${4 - item}/4 rounded-full`}
                />
              ))}
              <div className="flex w-full h-3" />
              {[1, 2, 3].map((item) => (
                <div
                  key={item}
                  className={`flex h-3 bg-animated-gradient-gray-700-gray-800 w-${4 - item}/4 rounded-full`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between h-8 mt-7">
        <div className="flex bg-animated-gradient-gray-700-gray-800 h-auto w-36 rounded-md" />
        <div className="flex h-full gap-x-2">
          {[1, 2, 3].map((item) => (
            <div className="flex bg-animated-gradient-gray-700-gray-800 h-auto w-24 rounded-md" />
          ))}
        </div>
      </div>
      <div className="flex justify-between mt-7">
        <Table
          isLoading={true}
          onClickRow={() => {}}
          items={[]}
          classes={{
            tableBodyStyles:
              "[&>table>thead]:bg-gray-800 bg-gray-800 rounded-md",
          }}
          header={[
            "game",
            "player",
            "time",
            "bet amount",
            "multiplier",
            "payout",
          ]}
          variant={null}
        />
      </div>
    </div>
  );
};
