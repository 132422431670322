import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { PublicKey } from "@solana/web3.js";

import {
  BalanceContext,
  IChainBalance,
  IChainBalances,
  ITokenCheckMeta,
} from "./BalanceContext";
import { PlayerContext } from "./PlayerContext";
import { PlatformContext } from "./PlatformContext";
import Player, { IPlayerToken } from "../sdk/playerAccount";
import { HouseContext } from "./HouseContext";
import { IHouseToken } from "../sdk/house";
import { IPlatformToken } from "../sdk/platform";
import { ICasinoToken } from "../types/token";
import { SOL_TOKEN_PUBKEY } from "../constants/sol";

export interface IMergedToken {
  house: IHouseToken | undefined;
  platform: IPlatformToken | undefined;
  wallet: IChainBalance | undefined;
  context: ICasinoToken | undefined;
}

export interface IAggregatedBalancesContext {
  solBalances: IChainBalances | undefined;
  mergedTokens: IMergedToken[] | undefined;
  primaryCombinedBalance: IChainBalance | undefined;
}

export const AggregatedBalancesContext =
  createContext<IAggregatedBalancesContext>({} as IAggregatedBalancesContext);

interface Props {
  children: any;
}

// BASIC CONTEXT TO COMBINE ATAs, PLAYER ACCOUNT TOKENS, HOUSE AND PLATFORM TOKEN CONFIGS
export const AggregatedBalancesProvider = ({ children }: Props) => {
  const [combinedBalances, setCombinedBalances] = useState<IChainBalances>();
  const { solBalances, selectedTokenMeta } = useContext(BalanceContext);
  const { playerAccount } = useContext(PlayerContext);

  // USED TO FILTER TOKENS
  const { tokensByIdentifier } = useContext(PlatformContext);
  const { house } = useContext(HouseContext);

  // COMBINE BALANCES FROM PLAYER AND ATAs, filtering for tokens accepted by platform and house.
  useEffect(() => {
    function handleCombiningBalances(
      chainAtaBalances: IChainBalances,
      playerAccount: Player | undefined,
      tokensById: Map<string, ITokenCheckMeta>,
    ) {
      // SET MAP TO COMBINE PLAYER WITH ATAs
      const playerTokenById = new Map<string, IPlayerToken>();
      playerAccount?.tokens.forEach((token) => {
        playerTokenById.set(token.pubkey.toString(), token);
      });

      let updatedBalances = { ...chainAtaBalances };

      if (updatedBalances.native != null) {
        updatedBalances.native.meta = tokensById.get("sol");
      }

      if (updatedBalances.primary != null) {
        updatedBalances.primary.meta = tokensById.get(
          updatedBalances.primary.identifier,
        );

        // ADD PLAYER BALANCES
        const playerBalance = playerTokenById.get(
          updatedBalances.primary.identifier,
        );

        if (playerBalance != null) {
          const tokensInPlayerAcc =
            playerBalance.balance + playerBalance.freeBetBalance;

          if (tokensInPlayerAcc > 0) {
            updatedBalances.primary.basis +=
              playerBalance.balance + playerBalance.freeBetBalance;
            updatedBalances.primary.uiAmount =
              updatedBalances.primary.basis /
              Math.pow(10, updatedBalances.primary.decimals);
          }
        }
      }

      if (updatedBalances.secondaries != null) {
        const updatedSecondaries: IChainBalance[] = [];

        updatedBalances.secondaries.forEach((balance) => {
          const tokenMeta = tokensById.get(balance.identifier);
          balance.meta = tokenMeta;

          if (tokenMeta != null && tokenMeta.platformToken != null) {
            const playerBalance = playerTokenById.get(balance.identifier);

            if (playerBalance != null) {
              const tokensInPlayerAcc =
                playerBalance.balance + playerBalance.freeBetBalance;

              if (tokensInPlayerAcc > 0) {
                balance.basis +=
                  playerBalance.balance + playerBalance.freeBetBalance;
                balance.uiAmount =
                  balance.basis / Math.pow(10, balance.decimals);
              }
            }

            updatedSecondaries.push(balance);
          }
        });

        updatedBalances.secondaries = updatedSecondaries;
      }

      setCombinedBalances(updatedBalances);
    }

    if (tokensByIdentifier == null || solBalances == null) {
      return;
    }

    handleCombiningBalances(solBalances, playerAccount, tokensByIdentifier);
  }, [solBalances, playerAccount, tokensByIdentifier, house]);

  const mergedTokens: IMergedToken[] | undefined = useMemo(() => {
    if (tokensByIdentifier == null || combinedBalances == null) {
      return;
    }

    const combinedBalanceById = new Map<string, IChainBalance | undefined>();
    const native = { ...combinedBalances.native };
    const primary = { ...combinedBalances.primary };
    const secindaries = [...(combinedBalances.secondaries || [])];
    combinedBalanceById.set("sol", native);

    if (primary.identifier) {
      combinedBalanceById.set(primary.identifier, primary);
    }

    secindaries?.forEach((secondary) => {
      if (secondary.identifier != primary.identifier) {
        combinedBalanceById.set(secondary.identifier, secondary);
      }
    });

    const newMergedTokens: IMergedToken[] = [];

    for (let [identifier, token] of tokensByIdentifier) {
      const houseToken = token.houseToken;
      let walletToken = combinedBalanceById.get(identifier)
        ? { ...combinedBalanceById.get(identifier) }
        : undefined;

      if (identifier == SOL_TOKEN_PUBKEY.toString()) {
        if (walletToken == null) {
          walletToken = native;
        } else {
          if (walletToken.basis != null) {
            walletToken.basis += native.basis || 0;
          } else {
            walletToken.basis = native.basis || 0;
          }

          if (walletToken.uiAmount != null) {
            walletToken.uiAmount += native.uiAmount || 0;
          } else {
            walletToken.uiAmount = native.uiAmount || 0;
          }
        }
      }

      if (houseToken != null && walletToken != null) {
        if (walletToken.uiAmount == null || walletToken.uiAmount <= 0) {
          walletToken.uiAmountBase = 0;
        } else if (
          house != null &&
          walletToken.identifier != null &&
          walletToken.basis != null
        ) {
          let identifier =
            walletToken.identifier == "sol"
              ? SOL_TOKEN_PUBKEY
              : new PublicKey(walletToken.identifier);

          walletToken.uiAmountBase = house?.approximateTokenAmountToBaseUI(
            identifier,
            walletToken.basis,
          );
        } else {
          console.warn("No exchange rate found for ", token.context?.symbol);
        }
      }

      const mergedToken: IMergedToken = {
        house: houseToken,
        platform: token.platformToken,
        context: token.context,
        wallet: walletToken,
      };

      if (
        mergedToken.wallet != null ||
        mergedToken.house != null ||
        mergedToken.platform != null
      ) {
        newMergedTokens?.push(mergedToken);
      }
    }

    return newMergedTokens;
  }, [tokensByIdentifier, combinedBalances, house]);

  const primaryCombinedBalance = useMemo(() => {
    if (combinedBalances?.primary == null) {
      return;
    }

    if (combinedBalances?.primary.identifier != SOL_TOKEN_PUBKEY.toString()) {
      return combinedBalances?.primary;
    } else {
      let newCombined = { ...combinedBalances.primary };

      if (combinedBalances.native != null) {
        let native = { ...combinedBalances.native };
        newCombined.basis += native.basis;
        newCombined.uiAmount += native.uiAmount;

        if (newCombined.uiAmountBase != null) {
          newCombined.uiAmountBase += native.uiAmountBase || 0;
        } else if (native.uiAmountBase != null) {
          newCombined.uiAmountBase = native.uiAmountBase;
        }
      }

      return newCombined;
    }
  }, [combinedBalances, selectedTokenMeta]);

  return (
    <AggregatedBalancesContext.Provider
      value={useMemo(
        () => ({
          solBalances: combinedBalances,
          mergedTokens: mergedTokens,
          primaryCombinedBalance: primaryCombinedBalance,
        }),
        [combinedBalances, mergedTokens, primaryCombinedBalance],
      )}
    >
      {children}
    </AggregatedBalancesContext.Provider>
  );
};
