import * as anchor from "@coral-xyz/anchor";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";
import Player from "./playerAccount";
import { sha256 } from "js-sha256";
import * as base58 from "bs58";

import { UNIX_DAY_IN_SECONDS } from "./constants";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { CollectableStatus } from "./betStream";

export type ImpliedDaySlot = {
  startDayTs: number;
  endDayTs: number;
  slotIdx: number;
  amount: number;
  status: string;
};

export type FormattedImpliedDaySlot = {
  startDay: Date;
  slotIdx: number;
  amount: number;
  amountUi: number;
  amountUsd: number;
  amountUsdUi: number;
  token: string;
  tokenIcon: string;
  status?: CollectableStatus;
};

export type ImpliedRewardCalendarForfeit = {
  token: PublicKey;
  amount: Number;
  rewardDate: Number;
  timestamp: Number;
};

export default class RewardCalendar {
  private _player: Player;
  private _publicKey: PublicKey;
  private _state: any;
  private _stateLoaded: boolean;
  private _impliedDaySlots: ImpliedDaySlot[];
  private _impliedForfeits: ImpliedRewardCalendarForfeit[];
  private _availableToCollect: number;
  private _futureCollectable: number;

  constructor(player: Player, rewardCalendarPubkey: PublicKey) {
    this._stateLoaded = false;
    this._player = player;
    this._publicKey = rewardCalendarPubkey;
  }

  static async load(
    player: Player,
    rewardCalendarPubkey: PublicKey,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    const rewardCalendar = new RewardCalendar(player, rewardCalendarPubkey);
    await rewardCalendar.loadState(commitmentLevel);
    return rewardCalendar;
  }

  static loadFromBuffer(
    player: Player,
    rewardCalendarPubkey: PublicKey,
    accountBuffer: Buffer,
  ) {
    const rewardCalendar = new RewardCalendar(player, rewardCalendarPubkey);
    rewardCalendar._state = player.program.coder.accounts.decode(
      "RewardCalendar",
      accountBuffer,
    );
    rewardCalendar.solveForImpliedDaySlots();
    rewardCalendar._stateLoaded = true;
    return rewardCalendar;
  }

  async loadState(commitmentLevel: anchor.web3.Commitment = "processed") {
    const state = await this.program.account.rewardCalendar.fetchNullable(
      this.publicKey,
      commitmentLevel,
    );
    this._state = state;
    this.solveForImpliedDaySlots();
    this._stateLoaded = true;
    return;
  }

  get player() {
    return this._player;
  }

  get ownerPubkey() {
    return this.player.ownerPubkey;
  }

  get platform() {
    return this.player.platform;
  }

  get house() {
    return this.player.platform.house;
  }

  get program() {
    return this.player.platform.house.program;
  }

  get publicKey() {
    return this._publicKey;
  }

  get state() {
    return this._state;
  }

  get tokenMintPubkey() {
    return this._state.tokenMint;
  }

  get lastRefresh(): number | null {
    return this._state ? Number(this._state.lastRefresh) : null;
  }

  get lastBonusExpires(): number | null {
    return this._state ? Number(this._state.lastBonusExpires) : null;
  }

  get lastRefreshSlotIdx(): number | null {
    if (this.lastRefresh) {
      return Math.floor(this.lastRefresh / UNIX_DAY_IN_SECONDS) % 28;
    }
    return null;
  }

  get lastBonusExpiresSlotIdx(): number | null {
    if (this.lastBonusExpires) {
      return Math.floor(this.lastBonusExpires / UNIX_DAY_IN_SECONDS) % 28;
    }
    return null;
  }

  solveSlotIdx(timestamp: number): number | null {
    if (this.lastRefresh) {
      const startLastRefershDayTs =
        Math.floor(this.lastRefresh / UNIX_DAY_IN_SECONDS) *
        UNIX_DAY_IN_SECONDS;
      if (timestamp < startLastRefershDayTs) {
        return null; // Since it wouldn't be on this board
      } else if (timestamp > this.lastRefresh + 27 * UNIX_DAY_IN_SECONDS) {
        return null; // Since it wouldn't be on this board
      } else {
        return Math.floor(timestamp / UNIX_DAY_IN_SECONDS) % 28;
      }
    }
    return null;
  }

  get currentSlotIdx(): number | null {
    let currentTs = Math.floor(Number(new Date()) / 1_000);
    return this.solveSlotIdx(currentTs);
  }

  get impliedDaySlots(): ImpliedDaySlot[] {
    return this._impliedDaySlots;
  }

  get formattedImpliedDaySlots(): FormattedImpliedDaySlot[] {
    const token = this.platform.rewardTokenConfig;

    return this._impliedDaySlots.map((slot) => ({
      ...slot,
      startDay: new Date(slot.startDayTs * 1000),
      token: token?.pubkey || "",
      tokenIcon: "",
      amountUi: slot.amount / Math.pow(10, token?.houseToken.decimals || 6),
      amountUsd: this.house.approximateTokenAmountToBase(
        new PublicKey(token?.pubkey || ""),
        slot.amount,
      ),
      amountUsdUi: this.house.approximateTokenAmountToBaseUI(
        new PublicKey(token?.pubkey || ""),
        slot.amount,
      ),
      status: undefined,
    }));
  }

  get impliedForfeits(): any[] {
    return this._impliedForfeits;
  }

  get availableToCollect() {
    return this._availableToCollect;
  }

  get futureCollectable() {
    return this._futureCollectable;
  }

  solveForImpliedDaySlots() {
    this._impliedDaySlots = [];
    this._availableToCollect = 0;
    this._futureCollectable = 0;
    this._impliedForfeits = [];
    var days: any[] = [];
    const currentTs = Math.floor(Number(new Date()) / 1_000);
    var dayStartTs =
      Math.floor(this.lastRefresh / UNIX_DAY_IN_SECONDS) * UNIX_DAY_IN_SECONDS;
    if (this.lastRefresh) {
      for (let i = 0; i < 28; i++) {
        const dayEndTs = dayStartTs + UNIX_DAY_IN_SECONDS;
        const daySlotIdx = this.solveSlotIdx(dayStartTs);
        const amount = Number(this._state.days[daySlotIdx]);
        var status: string = null;
        if (currentTs > dayEndTs) {
          status = "forfeit";
          this._impliedForfeits.push({
            token: this.tokenMintPubkey,
            amount: amount,
            rewardDate: dayStartTs,
            timestamp: dayEndTs,
          } as ImpliedRewardCalendarForfeit);
        } else if (currentTs < dayStartTs) {
          status = "future";
          this._futureCollectable += amount;
        } else {
          status = "availableToCollect";
          this._availableToCollect = amount;
        }
        this._impliedDaySlots.push({
          startDayTs: dayStartTs,
          endDayTs: dayEndTs,
          slotIdx: daySlotIdx,
          amount: amount,
          status: status,
        } as ImpliedDaySlot);
        dayStartTs = dayEndTs;
      }
    }
  }

  async collectIx(): Promise<TransactionInstruction> {
    const houseTokenPubkey = this.house.deriveHouseTokenAccountPubkey(
      this.tokenMintPubkey,
    );
    const oraclePubkey = this.house.getTokenConfigAndStatistics(
      this.tokenMintPubkey,
    )?.oracle;
    const tokenAccountOrWallet = await this.player.deriveTokenAccountPubkey(
      this.tokenMintPubkey,
    );
    const vault = await this.house.deriveHouseTokenVault(this.tokenMintPubkey);

    return await this.player.program.methods
      .rewardCollect({})
      .accounts({
        owner: this.player.ownerPubkey,
        house: this.player.house.publicKey,
        houseToken: houseTokenPubkey,
        platform: this.player.platform.publicKey,
        platformPayer: this.player.platform.derivePlatformPayerPubkey(),
        player: this.player.publicKey,
        rewardCalendar: this.publicKey,
        tokenMint: this.tokenMintPubkey,
        tokenAccountOrWallet: tokenAccountOrWallet,
        vault: vault,
        oracle: oraclePubkey,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: anchor.web3.SystemProgram.programId,
      })
      .instruction();
  }

  static deriveReferralAccountDiscriminator() {
    return Buffer.from(sha256.digest("account:RewardCalendar")).subarray(0, 8);
  }

  static async fetchAllRewardCalendarAccountsForPlayer(
    player: Player,
  ): Promise<RewardCalendar[]> {
    const rewardCalendarAccountPubkeysAndBuffers =
      await player.program.provider.connection.getProgramAccounts(
        player.program.programId,
        {
          filters: [
            {
              memcmp: {
                offset: 0, // Anchor account discriminator for RequestAccount type
                bytes: base58.encode(
                  RewardCalendar.deriveReferralAccountDiscriminator(),
                ),
              },
            },
            {
              memcmp: {
                offset: 8, // 8 (discriminator)
                bytes: base58.encode(player.publicKey.toBuffer()),
              },
            },
          ],
        },
      );
    const rewardCalendars = rewardCalendarAccountPubkeysAndBuffers.map(
      (rewardCalendarAccountPubkeyAndBuffer) =>
        RewardCalendar.loadFromBuffer(
          player,
          rewardCalendarAccountPubkeyAndBuffer.pubkey,
          rewardCalendarAccountPubkeyAndBuffer.account.data,
        ),
    );
    return rewardCalendars;
  }
}
