import { useMemo } from "react";
import IconFont from "../iconFont/IconFont";
import { twMerge } from "tailwind-merge";
import { classNames } from "../../../utils/styles/tailwind";

export enum CheckboxSize {
  sm = "SM",
  md = "MD",
}

export interface ICheckBox {
  checked: boolean;
  setChecked: Function;
  size: CheckboxSize;
  label?: string | React.ReactNode;
  classes?: {
    label?: string;
    checkbox?: string;
    checkboxChecked?: string;
  };
}

export const CheckBox = ({
  checked,
  setChecked,
  size,
  label,
  classes,
}: ICheckBox) => {
  const SIZE_CLASSES = useMemo(() => {
    if (size == CheckboxSize.md) {
      return "h-5.5 w-5.5";
    }

    if (size == CheckboxSize.sm) {
      return "h-4.5 w-4.5";
    }

    return "h-4.5 w-4.5";
  }, [size]);

  return (
    <div className="checkbox-wrapper">
      <label className="relative flex cursor-pointer items-center gap-3">
        <input
          className="sr-only"
          checked={checked}
          onChange={(value) => {
            setChecked(value.target.checked);
          }}
          type="checkbox"
        />
        <div
          className={twMerge(
            `${SIZE_CLASSES} flex cursor-pointer items-center justify-center rounded border-2 border-gray-600 bg-gray-950 
            ${checked ? twMerge("border-brand-purple-light bg-brand-purple-light [&>div]:visible", classes?.checkboxChecked) : null}
            focus:border-offset-0 hover:border-gray-600 hover:bg-gray-600
            focus:border-0 focus:outline-none focus-visible:outline-none
            active:border-[var(--brand-purple-dark-1)] 
            active:bg-[var(--brand-purple-dark-1)] [&>div]:hover:visible
            
          `,
            classes?.checkbox,
          )}
        >
          <IconFont
            name="check"
            size={size == CheckboxSize.md ? "xl" : "lg"}
            className="invisible"
          />
        </div>
        {label && <span className={classes?.label}>{label}</span>}
      </label>
    </div>
  );
};
