import { useContext, useEffect, useMemo, useState } from "react";
import { BaseModal } from "./BaseModal";
import { Tabs } from "./wallet/Tabs";
import {
  AggregatedBalancesContext,
  IMergedToken,
} from "../contexts/AggregatedBalancesContext";
import WalletIcon from "../assets/icons/wallet.svg";
import Icon from "../components/common/icon/Icon";
import CloseIcon from "../assets/icons/x.svg";
import { Loading } from "./Loading";
import { FundTab } from "./wallet/Fund";
import { GasMeterTab } from "./wallet/Gas";
import { Transfer } from "./wallet/Transfer";
import { SOL_TOKEN_PUBKEY } from "../constants/sol";
import { BalanceContext } from "../contexts/BalanceContext";
import { getPlatformTokens } from "../utils/config/utils";
import { defaultNetwork } from "../utils/chain/network";

export enum WalletTab {
  FUND = "Fund",
  TRANSFER = "Transfer",
  GAS = "Gas",
}

interface Props {
  open: boolean;
  closeModal: (value: boolean) => void;
  selectedTab: WalletTab;
  setSelectedTab: React.Dispatch<React.SetStateAction<WalletTab>>;
}

export default function WalletModal({
  open,
  closeModal,
  selectedTab,
  setSelectedTab,
}: Props) {
  const { mergedTokens } = useContext(AggregatedBalancesContext);
  // ENSURE SOL IS AVAILABLE FOR TRANSFER AND FUND EVEN IF NOT ON PLATFORM
  const { solBalances } = useContext(BalanceContext);
  const solContext = useMemo(() => {
    const allGames = getPlatformTokens(defaultNetwork);

    return allGames.find((token) => {
      return token.pubkey == SOL_TOKEN_PUBKEY.toString();
    });
  }, []);

  const walletMergedTokens = useMemo(() => {
    if (mergedTokens == null) {
      return [];
    }

    const hasSol = mergedTokens.find((mergedToken) => {
      return mergedToken.context?.pubkey == SOL_TOKEN_PUBKEY.toString();
    });

    if (hasSol != null) {
      return [...mergedTokens];
    }

    // ENSURE SOL IS AVAILABLE FOR FUND/TRANSFER
    const walletMerged = [...mergedTokens];
    const sol: IMergedToken = {
      wallet: solBalances?.native,
      platform: undefined,
      house: undefined,
      context: solContext,
    };
    walletMerged.push(sol);

    return walletMerged;
  }, [mergedTokens, solBalances, solContext]);

  const [selectedToken, setSelectedToken] = useState<IMergedToken>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedToken == null && walletMergedTokens != null) {
      setSelectedToken(walletMergedTokens[0]);
    } else if (walletMergedTokens != null && selectedToken != null) {
      setSelectedToken(
        walletMergedTokens.find((token) => {
          return token.context?.pubkey == selectedToken.context?.pubkey;
        }),
      );
    }
  }, [walletMergedTokens]);

  return (
    <BaseModal open={open} hideModal={closeModal}>
      {loading == true ? (
        <Loading message="Loading" title="Please wait..." />
      ) : (
        <div className="flex w-[85vw] md:w-[520px] flex-col items-start gap-y-3 bg-gray-800">
          <div className="flex self-stretch justify-between items-center max-h-[var(--modal-header-height)]">
            <div className="flex gap-x-1.5">
              <div className="text-gray-400 w-[20px] h-[20px]">
                <WalletIcon />
              </div>

              <div className="flex items-center">Wallet</div>
            </div>
            <div className="text-gray-50" role="button" onClick={closeModal}>
              <Icon size="lg" inheritStroke icon={<CloseIcon />} />
            </div>
          </div>
          <Tabs
            selectedTab={selectedTab}
            updateSelectedTab={setSelectedTab}
            tabs={WalletTab}
            classes={{
              activeTabClasses: "bg-white text-gray-800",
              tabClasses: "font-semibold",
            }}
          />
          {selectedTab == WalletTab.FUND ? (
            <FundTab
              loading={loading}
              setLoading={setLoading}
              mergedTokens={walletMergedTokens}
              selectedToken={selectedToken}
              setSelectedToken={setSelectedToken}
              closeModal={closeModal}
            />
          ) : (
            ""
          )}

          {selectedTab == WalletTab.GAS ? (
            <GasMeterTab
              loading={loading}
              setLoading={setLoading}
              closeModal={closeModal}
              setTab={setSelectedTab}
            />
          ) : (
            ""
          )}

          {selectedTab == WalletTab.TRANSFER ? (
            <Transfer
              mergedTokens={walletMergedTokens}
              selectedToken={selectedToken}
              setSelectedToken={setSelectedToken}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </BaseModal>
  );
}
