import React, {
  Fragment,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";

import { SidebarDesktop } from "./SideBarDesktop";
import { SideBarMobile } from "./SideBarMobile";
import { getPlatformGames } from "../../utils/config/utils";
import Nav from "./Nav";
import { NavContext } from "../../contexts/NavContext";
import { convertDocEleToReact } from "../../utils/svg/create-svg";
import { GameType } from "../../sdk/enums";
import { WrappedWalletContext } from "../../contexts/WrappedWalletContext";
import { DivvyContext } from "../../contexts/DivvyContext";
import { HouseContext } from "../../contexts/HouseContext";
import DivvyLogo from "../../assets/logo/divvy_logo.png";
import BettingIcon from "../../assets/icons/divvy_betting.svg";
import { twMerge } from "tailwind-merge";
import { SolanaConnect } from "../top-nav/WalletConnect";
import { AggregatedBalancesContext } from "../../contexts/AggregatedBalancesContext";
import { BalanceContext } from "../../contexts/BalanceContext";
import { DIVVY_BETTING_URL } from "../../constants/constants";

export function SideBar() {
  const { loggedIn, disconnect, walletPubkey } =
    useContext(WrappedWalletContext);
  const { solBalances, mergedTokens } = useContext(AggregatedBalancesContext);
  const { selectedTokenMeta, setSelectedTokenMeta } =
    useContext(BalanceContext);
  const { divvyUser, divvyPoints } = useContext(DivvyContext);
  const { houseToken } = useContext(HouseContext);

  const selectedToken = useMemo(() => {
    if (selectedTokenMeta == null || mergedTokens == null) {
      return;
    }

    const selectedTokenMintString = selectedTokenMeta.mint;

    return mergedTokens.find((token) => {
      return token?.context?.pubkey == selectedTokenMintString;
    });
  }, [selectedTokenMeta, mergedTokens]);

  const userPoint = divvyPoints.find(
    (point) => point.address === walletPubkey?.toString(),
  );

  const totalPoints =
    Number(userPoint?.points || 0) +
    Number(userPoint?.lpPoints || 0) +
    Number(userPoint?.communityPoints || 0) +
    Number(userPoint?.referralPoints || 0);

  const housepoolBalance = houseToken
    ? houseToken.availableTradingBalance / 10 ** houseToken.decimals
    : 0;

  const platformGames = useMemo(() => {
    return getPlatformGames().filter(
      (game) => game?.type !== GameType.BinaryOption,
    );
  }, []);

  const iconPng = (
    <img
      src={selectedToken?.context?.imageDarkPng || ""}
      className="h-[16px] w-[16px] mr-1"
    />
  );

  const [gameSvgs, setGameSvgs] = useState(new Map());
  const domParser = new DOMParser();
  const fetchGameSvg = async (svgUrl: string) => {
    fetch(svgUrl).then((res) =>
      res.text().then((svgText) => {
        setGameSvgs(
          gameSvgs.set(
            svgUrl,
            convertDocEleToReact(
              domParser.parseFromString(svgText, "image/svg+xml")
                ?.documentElement,
              null,
            ),
          ),
        );
      }),
    );
  };
  useEffect(() => {
    if (platformGames) {
      platformGames.forEach((game) => {
        fetchGameSvg(game.icon);
      });
    }
  }, [platformGames]);

  const { leftPanelOpen, toggleLeftPanel } = useContext(NavContext);

  return (
    <Fragment>
      <SideBarMobile sidebarOpen={leftPanelOpen}>
        <Fragment>
          {/* <Nav
              games={platformGames}
              getIconName={getIconName}
              leftBarIsOpen={true}
              toggleLeftPanel={toggleLeftPanel}
              closeAfterClick={true}
              footerContent={
                <Fragment>
                  
                </Fragment>
              }
            /> */}
          <div className="">
            <div className="flex justify-between items-center mb-5">
              <div>
                <img src={DivvyLogo} />
              </div>
              <div
                className={twMerge(
                  "flex space-x-2 [&_.wallet-adapter-button]:rounded-md [&_.wallet-adapter-button]:w-full [&_.wallet-adapter-dropdown]:w-full [&_.wallet-adapter-button]:justify-center [&_.wallet-adapter-button]:h-[40px]",
                  loggedIn
                    ? "[&_.wallet-adapter-button]:bg-white/10"
                    : "[&_.wallet-adapter-button]:bg-divvy-gradient",
                )}
              >
                <SolanaConnect user={divvyUser}></SolanaConnect>
              </div>
            </div>
            <div className="pt-3 pb-2 rounded-md px-3 flex justify-between my-5">
              <span>My Points</span>
              <span> {totalPoints.toFixed(2)}</span>
            </div>
            <div className="flex items-center my-1 h-[50px] px-4 rounded-md cursor-pointer bg-white/10 hover:bg-white/20" onClick={() => window.open(DIVVY_BETTING_URL, "_blank")}>
              <BettingIcon className="mr-1" transform="scale(0.8 0.8)"/>
              <span>Betting</span>
            </div>
            {/* <div className="pt-3 pb-2 rounded-md px-3 my-5 h-[40px] flex items-center">
              <span>House Pool </span> <span className="mx-3">|</span>
              {iconPng}
              <span> {housepoolBalance.toFixed(2)}</span>
            </div> */}
            
          </div>
        </Fragment>
      </SideBarMobile>

      {/* Static sidebar for desktop */}
      {/* <SidebarDesktop leftBarIsOpen={leftPanelOpen}>
        <Nav
          games={platformGames}
          getIconName={getIconName}
          leftBarIsOpen={leftPanelOpen}
          toggleLeftPanel={toggleLeftPanel}
          closeAfterClick={false}
        />
      </SidebarDesktop> */}
    </Fragment>
  );
}
