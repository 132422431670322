import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { BrowserPreferenceContext } from "../contexts/BrowserPreferenceContext";
import { SideBar } from "../components/left-nav/SideBar";
import { TopNav } from "../components/top-nav/TopNav";
import { BottomNav } from "../components/bottom-nav/BottomNav";
import { NavContext } from "../contexts/NavContext";
import { SCROLLBAR_CLASSES_BLACK } from "../styles/commonClasses";
import { useOnboardingHandler } from "../hooks/useOnboardingHandler";
import { BannerContext } from "../contexts/BannerContext";
import { Banner } from "../components/banners/Banner";
import { useModal } from "react-modal-hook";
import { GeoblockModal } from "../modals/GeoblockModal";
import { ErrorHandlingContext } from "../contexts/ErrorHandlingContext";
import { ErrorType } from "../types/error";

export const MainContainer = () => {
  const { theme } = useContext(BrowserPreferenceContext);
  const { rightPanelOpen, leftPanelOpen } = useContext(NavContext);
  const { warnings } = useContext(BannerContext);
  const { locationValidation } = useContext(ErrorHandlingContext);

  useOnboardingHandler();

  // CHECK IF GEOBLOCKED
  const [showGeoblockModal, hideGeoblockModal] = useModal(
    ({ in: open }) => (
      <GeoblockModal
        open={open}
        hideModal={() => {
          hideGeoblockModal();
        }}
      />
    ),
    [],
  );

  useEffect(() => {
    if (locationValidation.errorMessageByType.has(ErrorType.IP_RESTRICTED)) {
      // showGeoblockModal();
      window.open("https://app.divvy.bet/403/", "_self");
    }
  }, [locationValidation]);

  return (
    <>
      {/* TOP NAV ACCROSS FULL WIDTH */}
      <TopNav />

      <div>
        {/* LEFT SIDEBAR OR EXPANDABLE ON MOBILE */}
        <SideBar />

        <div
          // className={`
          // ${rightPanelOpen ? "md:pr-right-bar-width" : "md:pr-0"}
          // ${leftPanelOpen ? "md:pl-left-bar-width" : "md:pl-left-bar-collapsed-width"}
          // transition-all`}
          className={`
            ${rightPanelOpen ? "md:pr-right-bar-width" : "md:pr-0"}
            ${leftPanelOpen ? "md:pl-left-bar-width" : "md:pl-0"}
            transition-all`}
        >
          <main className="relative">
            {/* WARNING BANNERS */}
            {/* {warnings?.map((warning, index) => (
              <Banner key={index}>{warning.message}</Banner>
            ))} */}
            <div
              className={`
                scroll-fix max-h-screen-minus-bar overflow-y-scroll bg-global-bg p-4 pr-3 sm:p-6 md:h-screen md:max-h-screen lg:px-6 lg:py-5
                ${SCROLLBAR_CLASSES_BLACK}
              `}
            >
              <Outlet />
            </div>
          </main>
        </div>

        <BottomNav />
      </div>

      <ToastContainer
        className="max-sm:mx-3 max-sm:mb-20 "
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme}
      />
    </>
  );
};
