import { useContext, useCallback, useMemo, Fragment } from "react";
import GasLoad from "../../components/animations/gas-load/GasLoad";
import Button from "../../components/common/button/Button";
import { AggregatedBalancesContext } from "../../contexts/AggregatedBalancesContext";
import { useTokenAirdrop } from "../../hooks/useTokenAirdrop";
import { WalletTab } from "../WalletModal";
import SolIcon from "./../../assets/icons/sol.svg";
import { NetworkType, defaultNetwork } from "../../utils/chain/network";

interface IGasMeter {
  setTab: Function;
  closeModal: Function;
  setLoading: Function;
  loading: boolean;
}

export const GasMeterTab = ({
  loading,
  setTab,
  closeModal,
  setLoading,
}: IGasMeter) => {
  const { solBalances } = useContext(AggregatedBalancesContext);
  const { airdropSol } = useTokenAirdrop();
  const handleAirdropSol = useCallback(async () => {
    setLoading(true);
    await airdropSol(
      () => {
        setLoading(true);
      },
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
        closeModal();
      },
    );
  }, [airdropSol]);

  const isMainnet = useMemo(() => {
    return defaultNetwork == NetworkType.MAINNET;
  }, [defaultNetwork]);

  const numberOfTxns = useMemo(() => {
    return (solBalances?.native?.basis || 0) / 5000;
  }, [solBalances?.native]);
  const MAX_TXNS = 700;

  return (
    <Fragment>
      {/* GAS METER */}
      <div className="flex py-3 px-0 flex-col items-center gap-y-[10px] w-70 md:w-80 self-center">
        <GasLoad
          startValue={0}
          endValue={numberOfTxns}
          total={MAX_TXNS}
          solBalance={solBalances?.native?.uiAmount || 0}
          solBalanceUsd={solBalances?.native?.uiAmountBase || 0}
        />
      </div>
      <GasWarning numberOfTxns={numberOfTxns} maxTxns={MAX_TXNS} />
      <div className="flex items-start gap-x-4 self-stretch">
        <Button
          onClick={() => setTab(WalletTab.SWAP)}
          className="flex-1"
          variant="gray"
        >
          Swap
        </Button>
        <Button
          isLoading={loading}
          onClick={() => {
            if (isMainnet == true) {
              setTab(WalletTab.FUND);
            } else {
              handleAirdropSol();
            }
          }}
          className="flex-1"
          variant="primary"
        >
          Fund
        </Button>
      </div>
      <div className="flex p-3 items-start gap-x-1 self-stretch rounded-md border-2 border-solid border-gray-600">
        <div className="flex pt-[3px] items-start gap-x-[10px]">
          <div className="w-[12px] h-[12px]">
            <SolIcon />
          </div>
        </div>
        <div className="flex-1 text-gray-300 text-sm font-normal">
          SOL is necessary to power all actions on Zeebit.io - like betting or
          transferring funds. Although the amount required is very small (about
          0.00005 SOL or $0.0001), it’s important to maintain a sufficient
          balance to carry out actions like transfers.
        </div>
      </div>
    </Fragment>
  );
};

interface IGasWarning {
  numberOfTxns: number;
  maxTxns: number;
}

const GasWarning = ({ numberOfTxns, maxTxns }: IGasWarning) => {
  if (numberOfTxns > maxTxns) {
    return "";
  }

  if (numberOfTxns > 0) {
    return (
      <div className="flex p-2 md:p-3 items-center gap-x-3 items-center self-stretch rounded-md bg-gray-700 text-sm">
        <span>
          CAUTION:{" "}
          <span className="font-normal">You you are running low on gas</span>
        </span>
      </div>
    );
  }

  return (
    <div className="flex p-2 md:p-3 items-center gap-x-3 items-center self-stretch rounded-md bg-brand-warning text-sm">
      <span>
        CAUTION:{" "}
        <span className="font-normal">
          You have no gas for any actions including bets.
        </span>
      </span>
    </div>
  );
};
