export const SCROLLBAR_CLASSES =
  "scrollbar-thin scrollbar-rounded-xl scrollbar-thumb-transparent scrollbar-track-thumb-gray-800 [&:hover]:scrollbar-thumb-gray-900 overflow-y-auto overflow-x-hidden";

export const SCROLLBAR_CLASSES_BLACK =
  "scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-gray-600 scrollbar-track-gray-900";

export const SCROLLBAR_X_CLASSES_BLACK = `overflow-x-auto ${SCROLLBAR_CLASSES_BLACK}`;

export const SCROLLBAR_X_CLASSES =
  "scrollbar-thin scrollbar-rounded-xl scrollbar-thumb-transparent scrollbar-track-thumb-gray-800 [&:hover]:scrollbar-thumb-gray-900 overflow-x-scroll overflow-y-hidden";
export const BASE_BUTTON_CLASSES =
  "cursor-pointer border-0 leading-none relative disabled:cursor-not-allowed flex items-center ring-0 outline-0 font-semibold";
