export const GITBOOK_URL = "https://zeebit.gitbook.io/";
export const TWITTER_URL = "https://twitter.com/Zeebitio";
export const DISCORD_URL = "https://discord.gg/zeebit";
export const MEDIUM_URL = "https://medium.com/@Zeebit";
export const MAGIC_EDEN_ZEEBROS_URL =
  "https://magiceden.io/marketplace/zeebit_zeebros";
export const TENSOR_ZEEBROS_URL =
  "https://www.tensor.trade/trade/zeebit_zeebros";
export const SNIPER_ZEEBROS_URL =
  "https://www.sniper.xyz/collection/zeebit_zeebros";
export const GITBOOK_FAIRNESS_URL = `${GITBOOK_URL}zeebit.io/information/fairness`;
export const GITBOOK_PRIVACY_URL = `${GITBOOK_URL}zeebit.io/information/privacy`;
export const GITBOOK_RESPONSIBLE_GAMING_URL = `${GITBOOK_URL}zeebit.io/information/responsible-gaming`;
export const GITBOOK_VERIFIABLE_RANDOMNESS_URL = `${GITBOOK_URL}zeebit.io/technical-overview/smart-contract-structures/randomness-contract`;
export const NFT_GENERAL_BANNER_MORE_INFO_URL = `${GITBOOK_URL}zeebit.io/zeebro-profit-staking/introduction`;
export const SMART_CONTRACT_AUDIT_URL = `${GITBOOK_URL}zeebit.io/information/smart-contract-audits`;
