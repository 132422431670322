import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ProgramContext } from "./ProgramContext";
import { Program } from "@coral-xyz/anchor";
import RandomnessDispatcher from "../sdk/randomnessDispatcher";

export interface IRandomnessDispatcherContext {
  dispatcher: RandomnessDispatcher | undefined;
  dispatcherLoaded: boolean;
}

export const RandomnessDispatcherContext =
  createContext<IRandomnessDispatcherContext>(
    {} as IRandomnessDispatcherContext,
  );

interface Props {
  children: any;
}

export const RandomnessDispatcherProvider = ({ children }: Props) => {
  const [dispatcher, setDispatcher] = useState<RandomnessDispatcher>();
  const [dispatcherLoaded, setDispatcherLoaded] = useState(false);

  const { meta } = useContext(ProgramContext);

  useEffect(() => {
    async function loadDispatcher(program: Program) {
      try {
        const dispatcherPubkey = RandomnessDispatcher.deriveDispatcherPubkey(
          program.programId,
        );
        const dispatcher = await RandomnessDispatcher.load(
          program,
          dispatcherPubkey,
        );
        setDispatcher(dispatcher);
      } catch (e) {
        console.warn(`Issue loading the dispatcher from chain.`, e);
      } finally {
        setDispatcherLoaded(true);
      }
    }

    if (meta == null || meta.randomnessProgram == null) {
      return;
    }
    loadDispatcher(meta.randomnessProgram);
  }, [meta]);

  return (
    <RandomnessDispatcherContext.Provider
      value={useMemo(
        () => ({
          dispatcher: dispatcher,
          dispatcherLoaded: dispatcherLoaded,
        }),
        [dispatcher, dispatcherLoaded],
      )}
    >
      {children}
    </RandomnessDispatcherContext.Provider>
  );
};
