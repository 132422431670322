import { useCallback, useEffect, useRef } from "react";

export const useIntersectionObserver = (onLoadMore: Function) => {
  const threshold = 0.2;
  const intersectionObserverElementRef = useRef<HTMLDivElement | null>(null);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        onLoadMore?.();
      }
    },
    [onLoadMore],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: threshold,
    });

    if (intersectionObserverElementRef.current) {
      observer.observe(intersectionObserverElementRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [intersectionObserverElementRef, handleIntersection]);

  return intersectionObserverElementRef;
};
