export enum ChainType {
  SOLANA = "solana",
  ETHEREUM = "eth",
}

export const defaultChainType = ChainType.SOLANA;

export enum NetworkType {
  DEVNET = "devnet",
  MAINNET = "mainnet",
}

export const defaultNetworkType = NetworkType.DEVNET;
