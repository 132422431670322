import { ENV_NAME, RPC_READ_ENDPOINT } from "../env/env";

export enum NetworkType {
  DEVNET = "DEVNET",
  TESTNET = "TESTNET",
  MAINNET = "MAINNET",
  LOCALNET = "LOCALNET",
}

export const defaultNetwork: NetworkType =
  NetworkType[ENV_NAME as keyof typeof NetworkType];
export const defaultNetworkUrl = RPC_READ_ENDPOINT;
