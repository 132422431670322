import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { AnchorProvider, Idl, Program } from "@coral-xyz/anchor";
import { NetworkContext } from "./NetworkContext";
import { Connection, Keypair, PublicKey } from "@solana/web3.js";
import {
  CASHIER_PROGRAM_PUBKEY,
  CASINO_PROGRAM_PUBKEY,
  RANDOM_PROGRAM_PUBKEY,
} from "../sdk/constants";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import { IdlErrorCode } from "@project-serum/anchor/dist/cjs/idl";
import { SwitchboardProgram } from "@switchboard-xyz/solana.js";
import { NetworkType, defaultNetwork } from "../utils/chain/network";

import casinoIdlJson from "../sdk/idl/casino.json";

export interface IProgramContext {
  meta: IProgramMeta | undefined;
}
export interface IProgramMeta {
  anchorProvider: AnchorProvider | undefined;
  cashierProgram: Program | undefined;
  randomnessProgram: Program | undefined;
  casinoProgram: Program | undefined;
  nftStakingProgram: Program | undefined;
  speculateProgram: Program | undefined;
  permisionlessProgram: Program | undefined;
  switchboardProgram: SwitchboardProgram | undefined;
  errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>;
}

export const ProgramContext = createContext<IProgramContext>(
  {} as IProgramContext,
);

interface Props {
  children: any;
}

const toProgramIdlErrorByCode = (
  program: Program<Idl> | undefined,
): Map<number, IdlErrorCode> => {
  return (
    program?.idl?.errors?.reduce((result, item) => {
      result.set(item.code, item);

      return result;
    }, new Map<number, IdlErrorCode>()) || new Map()
  );
};

export const ProgramProvider = ({ children }: Props) => {
  const { client } = useContext(NetworkContext);
  const [meta, setMeta] = useState<IProgramMeta>();

  useEffect(() => {
    async function loadProgramMeta() {
      try {
        const notMainnet = defaultNetwork != NetworkType.MAINNET;

        const provider = new AnchorProvider(
          client,
          new NodeWallet(new Keypair()),
          {
            commitment: client.commitment,
            preflightCommitment: client.commitment,
            skipPreflight: false,
          },
        );

        const cashierPro = await loadProgram(provider, CASHIER_PROGRAM_PUBKEY);

        // USING LOCAL IDL AS ISSUE UPDATING ANCHOR IDL
        const casinoPro =
          notMainnet == true
            ? await loadProgram(provider, CASINO_PROGRAM_PUBKEY)
            : await loadProgramLocalIdl(
                provider,
                CASINO_PROGRAM_PUBKEY,
                casinoIdlJson as Idl,
              );
        const randomnessPro = await loadProgram(
          provider,
          RANDOM_PROGRAM_PUBKEY,
        );

        const errorByCodeByProgram = [
          cashierPro,
          casinoPro,
          randomnessPro,
        ].reduce((result, item) => {
          if (item != null) {
            result.set(
              item?.programId.toString(),
              toProgramIdlErrorByCode(item),
            );
          }

          return result;
        }, new Map<string, Map<number, IdlErrorCode>>());
        const switchboardProgram = await loadSwitchboardProgram(client);

        setMeta({
          anchorProvider: provider,
          cashierProgram: cashierPro,
          casinoProgram: casinoPro,
          randomnessProgram: randomnessPro,
          nftStakingProgram: undefined,
          speculateProgram: undefined,
          permisionlessProgram: undefined,
          switchboardProgram: switchboardProgram,
          errorByCodeByProgram: errorByCodeByProgram,
        });
      } catch (e) {
        console.warn(`Issue loading the program meta.`, e);
      }
    }

    async function loadProgramLocalIdl(
      provider: AnchorProvider,
      programPubkey: PublicKey,
      idl: Idl,
    ) {
      try {
        const program = new Program(idl, programPubkey, provider);

        return program;
      } catch (e) {
        console.warn(`Issue loading program.`, { e }, programPubkey.toString());
      }
    }

    async function loadProgram(
      provider: AnchorProvider,
      programPubkey: PublicKey,
    ) {
      try {
        const idl = await Program.fetchIdl(programPubkey, provider);
        const program = new Program(idl, programPubkey, provider);

        return program;
      } catch (e) {
        console.warn(`Issue loading program.`, { e }, programPubkey.toString());
      }
    }

    async function loadSwitchboardProgram(client: Connection) {
      try {
        const program = await SwitchboardProgram.load(client);
        return program;
      } catch (err) {
        console.error("Issue loading switchboard program", err);
      }
    }

    if (client == null) {
      return;
    }

    loadProgramMeta();
  }, [client]);

  return (
    <ProgramContext.Provider
      value={useMemo(
        () => ({
          meta: meta,
        }),
        [meta],
      )}
    >
      {children}
    </ProgramContext.Provider>
  );
};
