import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { toast } from "react-toastify";
import { TransitionGroup } from "react-transition-group";
import { ModalProvider as ReactModalProvider } from "react-modal-hook";

import "react-toastify/dist/ReactToastify.css";

import { ErrorHandlingProvider } from "./contexts/ErrorHandlingContext";
import { BalanceProvider } from "./contexts/BalanceContext";
import { HealthProvider } from "./contexts/HealthContext";
import { BrowserPreferenceProvider } from "./contexts/BrowserPreferenceContext";
import { HouseProvider } from "./contexts/HouseContext";
import { PlayerProvider } from "./contexts/PlayerContext";
import { AggregatedBalancesProvider } from "./contexts/AggregatedBalancesContext";
import { BetstreamingProvider } from "./contexts/BetstreamingContext";
import { NetworkProvider } from "./contexts/NetworkContext";
import { ProgramProvider } from "./contexts/ProgramContext";
import { ModalProvider } from "./contexts/ModalContext";
import { PlatformProvider } from "./contexts/PlatformContext";
import { NavProvider } from "./contexts/NavContext";
import { RandomnessDispatcherProvider } from "./contexts/RandomnessDispatcherContext";
import { UserPreferenceProvider } from "./contexts/UserPreferenceContext";
import { BannerProvider } from "./contexts/BannerContext";

import { ToasterContext } from "./contexts/ToasterContext";
import { MainContainer } from "./pages/MainContainer";
import { GamePagePreloader } from "./pages/preloaders/GamePagePreloader";
import { WrappedWalletProvider } from "./contexts/WrappedWalletContext";
import { SolanaWalletProvider } from "./contexts/SolanaWalletProvider";
import { DivvyProvider } from "./contexts/DivvyContext";

const Error404Page = lazy(() => import("./pages/Error404Page"));
const GameContainer = lazy(() => import("./containers/GameContainer"));

export const App = () => {
  return (
    <ToasterContext.Provider value={toast}>
      <ErrorHandlingProvider>
        <HealthProvider>
          <NetworkProvider>
            <ProgramProvider>
              <SolanaWalletProvider>
                <WrappedWalletProvider>
                  <DivvyProvider>
                    <UserPreferenceProvider>
                      <BrowserPreferenceProvider>
                        <BalanceProvider>
                          <RandomnessDispatcherProvider>
                            <HouseProvider>
                              <PlatformProvider>
                                <BetstreamingProvider>
                                  <PlayerProvider>
                                    <AggregatedBalancesProvider>
                                      <ModalProvider>
                                        <ReactModalProvider
                                          rootComponent={TransitionGroup}
                                        >
                                          <NavProvider>
                                            <BannerProvider>
                                              <BrowserRouter>
                                                <Routes>
                                                  <Route
                                                    path=""
                                                    element={<MainContainer />}
                                                  >
                                                    {/* GAME CONTEXT - spec state and instance state, logs listener, game result history, bet result history. load state, start WS */}
                                                    <Route
                                                      path=""
                                                      element={
                                                        <Suspense
                                                          fallback={
                                                            <GamePagePreloader />
                                                          }
                                                        >
                                                          <GameContainer />
                                                        </Suspense>
                                                      }
                                                    />

                                                    <Route
                                                      path="*"
                                                      element={
                                                        <Suspense
                                                          fallback={<></>}
                                                        >
                                                          <Error404Page
                                                            backHome={``}
                                                          />
                                                        </Suspense>
                                                      }
                                                    />
                                                  </Route>
                                                  <Route
                                                    path="*"
                                                    element={
                                                      <Suspense
                                                        fallback={<></>}
                                                      >
                                                        <Error404Page
                                                          backHome={``}
                                                        />
                                                      </Suspense>
                                                    }
                                                  />
                                                </Routes>
                                              </BrowserRouter>
                                            </BannerProvider>
                                          </NavProvider>
                                        </ReactModalProvider>
                                      </ModalProvider>
                                    </AggregatedBalancesProvider>
                                  </PlayerProvider>
                                </BetstreamingProvider>
                              </PlatformProvider>
                            </HouseProvider>
                          </RandomnessDispatcherProvider>
                        </BalanceProvider>
                      </BrowserPreferenceProvider>
                    </UserPreferenceProvider>
                  </DivvyProvider>
                </WrappedWalletProvider>
              </SolanaWalletProvider>
            </ProgramProvider>
          </NetworkProvider>
        </HealthProvider>
      </ErrorHandlingProvider>
    </ToasterContext.Provider>
  );
};
