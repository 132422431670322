interface IWarningMessage {
  title?: string;
  classNames?: string;
  message: string;
  link?: any;
}

export const WarningMessage = ({
  title,
  message,
  link,
  classNames = "",
}: IWarningMessage) => {
  return (
    <div
      className={`flex flex-col p-3 items-start gap-1 self-stretch rounded-md bg-brand-warning text-sm ${classNames}`}
    >
      {title && <div>{title}</div>}
      <div className="font-normal">{message}</div>
      {link && <div>{link}</div>}
    </div>
  );
};
