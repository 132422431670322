import { useEffect, useMemo, useState } from "react";
import { useErrorMessageValidation } from "./validation/useErrorMessageValidation";
import { ErrorType } from "../types/error";
import { NetworkType, defaultNetwork } from "../utils/chain/network";

const SANCTIONED_COUNTRIES = [
  { country: "Afghanistan", code: "AF" },
  { country: "Albania", code: "AL" },
  { country: "Belarus", code: "BY" },
  { country: "British Virgin Islands", code: "VG" },
  { country: "Burma (Myanmar)", code: "MM" },
  { country: "Central Republic of Africa", code: "CF" },
  { country: "Cayman Islands", code: "KY" },
  { country: "Cuba", code: "CU" },
  { country: "Curacao", code: "CW" },
  { country: "Democratic Republic of Congo", code: "CD" },
  { country: "Denmark", code: "DK" },
  // { country: "France", code: "FR" },
  { country: "Germany", code: "DE" },
  { country: "Guinea", code: "GN" },
  { country: "Guinea Bissau", code: "GW" },
  { country: "Haiti", code: "HT" },
  { country: "Iran", code: "IR" },
  { country: "Iraq", code: "IQ" },
  { country: "Libya", code: "LY" },
  { country: "Netherlands", code: "NL" },
  { country: "Nicaragua", code: "NI" },
  { country: "North Korea", code: "KP" },
  { country: "Portugal", code: "PL" },
  { country: "Russia", code: "RU" },
  { country: "Somalia", code: "SO" },
  { country: "Sudan", code: "SD" },
  { country: "South Sudan", code: "SS" },
  { country: "Spain", code: "ES" },
  { country: "Syria", code: "SY" },
  { country: "Tunisia", code: "TN" },
  { country: "Turkey", code: "TR" },
  { country: "Venezuela", code: "VE" },
  { country: "Yemen", code: "YE" },
  { country: "Zimbabwe", code: "ZW" },
  { country: "United Kingdom", code: "GB" },
  { country: "United States", code: "US" },
];

export interface IpMeta {
  isAllowed: boolean;
  country:
    | {
        country: string;
        code: string;
      }
    | undefined;
}

export default function useIpAddressCheck() {
  const [ipMeta, setIpMeta] = useState<IpMeta>();
  const loactionValidation = useErrorMessageValidation();

  useEffect(() => {
    async function getCountryCodeCloudflare() {
      const response = await fetch(`https://www.cloudflare.com/cdn-cgi/trace`);
      const parsedResponse = await response.text();
      const ipLocation = parsedResponse.match(/loc=(.+)/);

      return ipLocation ? ipLocation[1] : undefined;
    }
    async function getCountryCodeBonfida() {
      const response = await fetch(`https://countrycode.bonfida.workers.dev/`);
      const parsedResponse = await response.json();

      return parsedResponse.countryCode;
    }

    const checkIpLocation = async () => {
      let ipCountryCode: string | null = null;

      try {
        const cloudflareCode = await getCountryCodeCloudflare();

        if (cloudflareCode != null && cloudflareCode.length == 2) {
          ipCountryCode = cloudflareCode;
        }
      } catch (err) {
        console.warn("Issue fetching country code cloudflare", err);
      }

      if (ipCountryCode == null) {
        try {
          const bonfidaCC = await getCountryCodeBonfida();

          if (bonfidaCC != null && bonfidaCC.length == 2) {
            ipCountryCode = bonfidaCC;
          }
        } catch (err) {
          console.warn("Issue fetching country code bonfida", err);
        }
      }

      if (ipCountryCode != null && ipCountryCode.length == 2) {
        const sanctionedCountry = SANCTIONED_COUNTRIES.find(
          (country) => country.code == ipCountryCode,
        );

        setIpMeta({
          isAllowed: sanctionedCountry == null,
          country: sanctionedCountry,
        });
      } else {
        setIpMeta({
          isAllowed: true,
          country: {
            country: "unknown",
            code: "unknown",
          },
        });
      }
    };

    // ONLY DO CHECK ON MAINNET
    if (defaultNetwork != NetworkType.MAINNET) {
      setIpMeta({
        isAllowed: true,
        country: {
          country: "unknown",
          code: "unknown",
        },
      });

      return;
    }

    checkIpLocation();
  }, []);

  useEffect(() => {
    if (
      ipMeta?.isAllowed == false &&
      loactionValidation.errorMessageByType.has(ErrorType.IP_RESTRICTED) ==
        false
    ) {
      loactionValidation.addErrorMessage({
        type: ErrorType.IP_RESTRICTED,
        message: "Restricted IP",
        title: "Restricted IP",
      });
    } else if (
      loactionValidation.errorMessageByType.has(ErrorType.IP_RESTRICTED) == true
    ) {
      loactionValidation.removeErrorMessage(ErrorType.IP_RESTRICTED);
    }
  }, [ipMeta]);

  return useMemo(() => {
    return {
      ipMeta: ipMeta,
      loactionValidation: loactionValidation,
    };
  }, [ipMeta, loactionValidation.errorMessages]);
}
