export enum ErrorType {
  // STATUS CHECKS - HOUSE
  HOUSE_NOT_ACTIVE = "house not active",
  HOUSE_TOKEN_NOT_ACTIVE = "house token not active",
  HOUSE_TOKEN_BALANCE_EMPTY = "house token balance empty",

  // STATUS CHECKS - PLATFORM
  PLATFORM_NOT_ACTIVE = "platform not active",
  PLATFROM_TOKEN_NOT_ACTIVE = "platform token not active",

  // STATUS CHECKS - GAME SPEC
  GAME_NOT_ACTIVE = "Game not active",
  GAME_TOKEN_NOT_SUPPORTED = "Token not supported for game",
  GAME_TOKEN_NOT_ACTIVE = "Token not active for game",

  // STANDARD FOR ALL GAMES
  WALLET_NOT_CONNECTED = "wallet not connected",
  WAGER_BELOW_MINIMUM = "wager below minimum",
  WAGER_OUTSIDE_INCREMENTS = "wager outside increments",
  INSUFFICIENT_HOUSE_BALANCE = "Max bet payout is above the house balance.",
  INSUFFICIENT_SOL_FOR_TX = "insufficient sol for tx",
  INSUFFICIENT_TOKEN_BALANCE = "insufficient token balance",
  MULTIPLIER_TOO_LOW = "multiplier too low",

  // SPECIFIC FOR COIN TOSS
  TOO_MANY_COINS = "too many coins selected",
  TOO_MANY_CORRECT_COINS = "too many correct coins",

  // SPECIFIC FOR LIMBO
  MULTIPLIER_TOO_HIGH = "multiplier too high",
  MULTIPLIER_OUTSIDE_INCREMENTS = "multiplier outside increments",

  // SPECIFIC FOR PLINKO
  TOO_MANY_ROWS = "too many rows selected",
  NOT_ENOUGH_ROWS = "not enough rows selected",
  TOO_MANY_BALLS = "too many balls selected",
  UNSUPPORTED_RISK_LEVEL = "unsupported risk level",

  // DICE SPECIFIC CHECKS
  TOO_MANY_ROLLS = "too many rolls selected",
  DICE_TOO_LOW = "dice too low",
  DICE_TOO_HIGH = "dice too high",
  DICE_OUTSIDE_INCREMENT = "dice outside increment",

  // ROULETTE SPECIFIC CHECKS
  TOO_MANY_BETS = "too many bets",

  // MINES SPECIFIC CHECKS
  NO_CELLS_SELECTED = "no cells selected",

  // KENO SPECIFIC
  TOO_MANY_SELECTIONS = "too many numbers selected",
  NONE_SELECTED = "no numbers selected",

  // BINARY OPTIONS
  FEED_NOT_SUPPORTED = "feed not supported",
  TIME_INTERVAL_NOT_SUPPORTED = "time interval not supported",
  TIME_INTERVAL_NOT_ACTIVE = "time interval not active",
  EXCEEDED_DIRECTIONAL_EXPOSURE_UP = "exceeded directional exposure up",
  EXCEEDED_DIRECTIONAL_EXPOSURE_DOWN = "exceeded directional exposure down",
  CONFIDENCE_ABOVE_MAX = "confidence above max",

  // PERMISIONLESS
  GAME_TOKEN_INSTANCE_NOT_ACTIVE = "Game Token Instance Not Active",
  OUTSIDE_ALLOWED_BETTING_TIME = "Outside allowed betting time.",

  // WARNINGS
  NO_INTERNET = "no internet",
  SOLANA_DOWN = "solana down",
  SOLANA_SLOW = "solana slow",
  IP_RESTRICTED = "IP Restricted",
  TIME_OUT_ACTIVE = "Time out",
  SELF_EXCLUSION_ACTIVE = "Self exclusion",
  TERMS_AND_CONDITIONS_UNSIGNED = "Terms and conditions",
}

export interface IGameError {
  type: ErrorType;
  title: any;
  message: any;
}
