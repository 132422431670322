import IconButton from "../common/button/IconButton";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { NavContext } from "../../contexts/NavContext";

export const BottomNav = () => {
  const navigate = useNavigate();
  const { leftPanelOpen, toggleLeftPanel, closeAllBars } =
    useContext(NavContext);

  return (
    <div className="z-40 flex fixed md:hidden w-full bottom-0 h-top-bar-height bg-gray-800 items-center justify-between px-4 bottom-nav-shadow">
      <IconButton
        className="bg-gray-800"
        size="lg"
        iconName="hamburger_sm"
        onClick={() => {
          if (leftPanelOpen == true) {
            toggleLeftPanel(false);
          } else {
            closeAllBars();
            toggleLeftPanel(true);
          }
        }}
      />
      <IconButton
        className="bg-gray-800"
        size="lg"
        iconName="home"
        onClick={() => {
          closeAllBars();
          navigate(``);
        }}
      />
    </div>
  );
};
