import * as anchor from "@coral-xyz/anchor";
import {
  PublicKey,
  SystemProgram,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  createAssociatedTokenAccountInstruction,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import { sha256 } from "js-sha256";
import * as base58 from "bs58";

import Platform from "./platform";
import {
  listenForTransaction,
  getRewardInfoFromSlots,
  toRewardTypeString,
} from "./utils";
import { UNIX_DAY_IN_SECONDS } from "./constants";
import { RewardType } from "./enums";
import RewardCalendar from "./rewardCalendar";
import { IHouseToken } from "./house";

export interface IBenefitRate {
  rakebackBaseRate: number;
  rakebackNftAddOn: number;
  rakebackStakingAddOn: number;
  rakebackBoost: number;
  rakebackBoostUntil: Date | null;
  dailyBonusAccrualRate: number;
  weeklyBonusAccrualRate: number;
  monthlyBonusAccrualRate: number;
  levelUpBonusAccrualRate: number;
  rakebackBoostOnClaim: number;
  rakebackBoostOnClaimDurationSeconds: number;
}

export interface ICollectibleBenefits {
  rakeback: FormattedImpliedRewardInfo | null;
  daily: FormattedImpliedRewardInfo | null;
  weekly: FormattedImpliedRewardInfo | null;
  monthly: FormattedImpliedRewardInfo | null;
  levelUp: FormattedImpliedRewardInfo | null;
  collectable: any; // unknown
  totalAccruing: number;
  totalCollectible: number;
}

export interface IPlayerToken {
  balance: number;
  freeBetBalance: number;
  freeBetExpires: number;
  houseTokenIdx: number;
  houseToken: IHouseToken;
  pastBetCount: number;
  pastWinCount: number;
  pastLossCount: number;
  pastPaidOut: number;
  pastWagered: number;
  pubkey: PublicKey;
  rakebackSlotsAlternating: any[];
}

export type ImpliedRewardForfeit = {
  rewardType: RewardType;
  rewardTypeString: string;
  relatesTo: anchor.BN;
  valueBase: anchor.BN;
  timestamp: anchor.BN;
};

export type FormattedImpliedRewardForfeit = {
  rewardType: RewardType;
  relatesTo: Date;
  valueBase: number;
  valueBaseUi: number;
  timestamp: Date;
};

export type ImpliedRewardInfo = {
  rewardType: RewardType;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  amountCurrentlyAccruing: number;
  amountAvailableToCollect: number;
  amountImpliedForfeit: number;
  impliedForfeits: ImpliedRewardForfeit[];
};

export type FormattedImpliedRewardInfo = {
  rewardType: RewardType;
  rewardTypeString: string;
  currentPeriodStartDate: Date;
  currentPeriodStart: number;
  currentPeriodEndDate: Date;
  currentPeriodEnd: number;
  amountCurrentlyAccruing: number;
  amountAvailableToCollect: number;
  amountImpliedForfeit: number;
  amountCurrentlyAccruingUi: number;
  amountAvailableToCollectUi: number;
  amountImpliedForfeitUi: number;
  impliedForfeits: FormattedImpliedRewardForfeit[];
};

export const formatImpliedReward = (
  impliedReward: ImpliedRewardInfo | null,
  rewardTokenDecimals: number,
): FormattedImpliedRewardInfo | null => {
  if (impliedReward == null) {
    return null;
  }

  const rewardTypeString = toRewardTypeString(impliedReward.rewardType);

  return {
    ...impliedReward,
    amountCurrentlyAccruingUi:
      impliedReward.amountCurrentlyAccruing / Math.pow(10, rewardTokenDecimals),
    amountAvailableToCollectUi:
      impliedReward.amountAvailableToCollect /
      Math.pow(10, rewardTokenDecimals),
    amountImpliedForfeitUi:
      impliedReward.amountImpliedForfeit / Math.pow(10, rewardTokenDecimals),
    currentPeriodStartDate: new Date(impliedReward.currentPeriodStart * 1000),
    currentPeriodEndDate: new Date(impliedReward.currentPeriodEnd * 1000),
    rewardTypeString: rewardTypeString,
    impliedForfeits: impliedReward.impliedForfeits.map((forefit) => ({
      relatesTo: new Date(forefit.relatesTo.toNumber() * 1000),
      timestamp: new Date(forefit.timestamp.toNumber() * 1000),
      valueBase: forefit.valueBase.toNumber(),
      valueBaseUi:
        forefit.valueBase.toNumber() / Math.pow(10, rewardTokenDecimals),
      rewardType: forefit.rewardType,
      rewardTypeString: rewardTypeString,
    })),
  };
};

export const MAX_DATE_BN = new anchor.BN("9223372036854775807");

export default class Player {
  private _platform: Platform;
  private _ownerPubkey: PublicKey;
  private _playerAccountPubkey: PublicKey;
  private _state: any;
  private _stateLoaded: boolean;
  private _sevenDayRollingWagered: { value: number; timestamp: Date }[] | null;
  private _impliedRakeback: ImpliedRewardInfo | null;
  private _impliedDailyBonus: ImpliedRewardInfo | null;
  private _impliedWeeklyBonus: ImpliedRewardInfo | null;
  private _impliedMonthlyBonus: ImpliedRewardInfo | null;
  private _impliedLevelUpBonus: ImpliedRewardInfo | null;
  private _impliedForfeits: ImpliedRewardForfeit[];
  private _rewardCalendars: RewardCalendar[] | null;

  constructor(
    platform: Platform,
    ownerPubkey?: PublicKey,
    playerPubkey?: PublicKey,
  ) {
    this._stateLoaded = false;
    this._platform = platform;
    if (ownerPubkey != null) {
      this._ownerPubkey = ownerPubkey;
    }
    if (!playerPubkey) {
      this._playerAccountPubkey = this.derivePlayerAccountPubkey(ownerPubkey);
    } else {
      this._playerAccountPubkey = playerPubkey;
    }
  }

  static async load(
    platform: Platform,
    ownerPubkey: PublicKey,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    const player = new Player(platform, ownerPubkey, undefined);
    await player.loadState(commitmentLevel);
    return player;
  }

  static async loadFromPlayerPubkey(
    platform: Platform,
    playerPubkey: PublicKey,
    ownerPubkey?: PublicKey | null,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    const player = new Player(platform, ownerPubkey, playerPubkey);
    await player.loadState(commitmentLevel);
    player._ownerPubkey =
      player?._state?.owner != null ? player._state.owner : ownerPubkey;
    return player;
  }

  static loadFromBuffer(
    platform: Platform,
    ownerPubkey: PublicKey,
    accountBuffer: Buffer,
  ) {
    const player = new Player(platform, ownerPubkey);
    player._state = platform.program.coder.accounts.decode(
      "Player",
      accountBuffer,
    );
    player.solveImpliedStates();
    player._stateLoaded = true;
    return player;
  }

  static loadFromState(platform: Platform, ownerPubkey: PublicKey, state: any) {
    const player = new Player(platform, ownerPubkey);
    player._state = state;
    player.solveImpliedStates();
    player._stateLoaded = true;
    return player;
  }

  async loadRewardCalendars() {
    const rewardCalendars =
      await RewardCalendar.fetchAllRewardCalendarAccountsForPlayer(this);
    this._rewardCalendars = rewardCalendars;

    return this;
  }

  async loadState(commitmentLevel: anchor.web3.Commitment = "processed") {
    const state = await this.program.account.player.fetchNullable(
      this.publicKey,
      commitmentLevel,
    );

    this._state = state;
    this.solveImpliedStates();

    // LOAD REWARD CALENDARS
    if (state != null) {
      await this.loadRewardCalendars();
    }

    this._stateLoaded = true;
    return;
  }

  async checkPlayerAccountInitialized(): Promise<boolean> {
    if (!this._stateLoaded) {
      await this.loadState();
    }
    if (this._state) {
      return true;
    } else {
      return false;
    }
  }

  async checkPlayerAccountSupportsToken(
    tokenMintPubkey: PublicKey,
  ): Promise<boolean> {
    const playerInitialized = await this.checkPlayerAccountInitialized();
    if (!playerInitialized) {
      return false;
    }
    {
      if (this._state) {
        const tokenMintString = tokenMintPubkey.toString();
        const supportedTokenMintStrings = this.listTokenMints.map(
          (mintPubkey) => mintPubkey.toString(),
        );
        return supportedTokenMintStrings.includes(tokenMintString);
      } else {
        return false;
      }
    }
  }

  async checkPlayerHasAta(tokenMintPubkey: PublicKey): Promise<boolean> {
    try {
      const ataInfo =
        await this.program.provider.connection.getTokenAccountBalance(
          await this.deriveTokenAccountPubkey(tokenMintPubkey),
        );
      if (ataInfo != null) {
        return true;
      }

      return false;
    } catch (err) {
      return false;
    }
  }

  static derivePlayerAccountDiscriminator() {
    return Buffer.from(sha256.digest("account:Player")).subarray(0, 8);
  }

  derivePlayerAccountPubkey(ownerPubkey?: PublicKey): PublicKey {
    const [pk, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("player"),
        this.platform.publicKey.toBuffer(),
        ownerPubkey ? ownerPubkey.toBuffer() : this.ownerPubkey.toBuffer(),
      ],
      this.program.programId,
    );
    return pk;
  }

  static derivePlayerAccountPubkey(
    ownerPubkey: PublicKey,
    platform: Platform,
  ): PublicKey {
    const [pk, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("player"),
        platform.publicKey.toBuffer(),
        ownerPubkey.toBuffer(),
      ],
      platform.program.programId,
    );
    return pk;
  }

  deriveRewardCalendarPubkey(tokenMintPubkey: PublicKey): PublicKey {
    const [pk, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("reward_calendar"),
        this.publicKey.toBuffer(),
        tokenMintPubkey.toBuffer(),
      ],
      this.program.programId,
    );
    return pk;
  }

  async deriveTokenAccountPubkey(
    tokenMintPubkey: PublicKey,
  ): Promise<PublicKey> {
    return await this.house.derivePlayerAssociatedTokenAccount(
      this.ownerPubkey,
      tokenMintPubkey,
    );
  }

  static serializeUsernameToBytes20(username: string): Buffer {
    if (username.length > 20) {
      throw Error(
        `Username cannot be >20 characters in length: ${username} (${username.length} characters)`,
      );
    }
    return Buffer.concat([anchor.utils.bytes.utf8.encode(username)], 20);
  }

  static serializeAvatarToBytes32(avatar: string): Buffer {
    return Buffer.concat([anchor.utils.bytes.utf8.encode(avatar)], 32);
  }

  static validate_username(username: string): boolean {
    const acceptableBytes = [];
    const usernameBytes = anchor.utils.bytes.utf8.encode(username);
    if (usernameBytes.length < 8) {
      throw new Error("Username too short. Must be >8 characters");
    }
    if (usernameBytes.length > 20) {
      throw new Error("Username too long. Must be <20 characters");
    }
    usernameBytes.forEach((c) => {
      if (
        !(
          (c >= 48 && c <= 57) ||
          (c >= 65 && c <= 90) ||
          (c >= 97 && c <= 122) ||
          c == 95
        )
      ) {
        throw new Error(
          "Invalid character only a-z, A-Z, 0-9, and _ values allowed",
        );
      }
    });
    return true;
  }

  deriveUsernameAccountPubkey(username: string): PublicKey {
    const usernameBytes20 = Player.serializeUsernameToBytes20(username);
    const [usernameAccountPubkey, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("username"),
        this.platform.publicKey.toBuffer(),
        usernameBytes20,
      ],
      this.program.programId,
    );
    return usernameAccountPubkey;
  }

  get platform() {
    return this._platform;
  }

  get house() {
    return this.platform.house;
  }

  get program() {
    return this.platform.house.program;
  }

  get publicKey() {
    return this._playerAccountPubkey;
  }

  get ownerPubkey() {
    return this._ownerPubkey;
  }

  get state() {
    return this._state;
  }

  get listTokenMints(): PublicKey[] {
    return this._state
      ? this._state.tokens.map((tkn) =>
          this.house.getTokenPubkeyFromHouseTokenIdx(tkn.houseTokenIdx),
        )
      : null;
  }

  get listTokens(): any[] {
    if (this._state) {
      this._state.tokens.forEach((tkn) => {
        tkn.pubkey = this.house.getTokenPubkeyFromHouseTokenIdx(
          tkn.houseTokenIdx,
        );
      });
      return this._state.tokens;
    } else {
      return [];
    }
  }

  get tokens(): IPlayerToken[] {
    if (this._state) {
      return this._state.tokens.map((tkn) => {
        return {
          balance: Number(tkn.balance),
          freeBetBalance: Number(tkn.freeBetBalance),
          freeBetExpires: Number(tkn.freeBetExpires),
          houseTokenIdx: tkn.houseTokenIdx,
          houseToken: this.house.tokens[tkn.houseTokenIdx],
          pastBetCount: Number(tkn.pastBetCount),
          pastLossCount: Number(tkn.pastLossCount),
          pastWinCount: Number(tkn.pastWinCount),
          pastPaidOut: Number(tkn.pastPaidOut),
          pastWagered: Number(tkn.pastWagered),
          pubkey: this.house.getTokenPubkeyFromHouseTokenIdx(tkn.houseTokenIdx),
          rakebackSlotsAlternating: tkn.rakebackSlotsAlternating,
        };
      });
    } else {
      return [];
    }
  }

  static decodeUsername(username: any) {
    if (username == Buffer.alloc(20)) {
      return "";
    } else {
      return anchor.utils.bytes.utf8
        .decode(Buffer.from(username))
        .replaceAll("\x00", "");
    }
  }

  get username() {
    if (this._state) {
      if (this._state.username == Buffer.alloc(20)) {
        return "";
      } else {
        return anchor.utils.bytes.utf8
          .decode(Buffer.from(this._state.username))
          .replaceAll("\x00", "");
      }
    }
  }

  get hidden() {
    if (this._state) {
      return this._state.hidden;
    } else {
      return true;
    }
  }

  get avatarType() {
    return this._state ? this._state.avatarType : null;
  }

  get avatarSource() {
    if (this._state) {
      if (this.avatarType == 0) {
        return null;
      } else if (this.avatarType == 1) {
        return ""; // TODO: Get url from NFT meta
      } else if (this.avatarType == 2) {
        return ""; // TODO: Get url from Arweave reference
      } else if (this.avatarType == 3) {
        return ""; // TODO: Get url from IPFS reference
      }
    }
    return null;
  }

  get avatar(): PublicKey | undefined {
    return this.state?.avatar;
  }

  get rank() {
    return this._state ? this._state.rank : 0;
  }

  get xpAllTime() {
    return this._state ? this._state.xpAllTime : 0;
  }

  get xpSpent() {
    return this._state ? this._state.xpSpent : 0;
  }

  get xpNextRank() {
    return this._state ? this._state.xpForNextRank : 0;
  }

  get dueLevelUp() {
    return this._state
      ? this.xpAllTime != 0 && Number(this.xpAllTime) > Number(this.xpNextRank)
      : false;
  }

  get pastWageredInBase() {
    return this._state ? Number(this._state.pastWagered) : 0;
  }

  get pastPaidOutInBase() {
    return this._state ? Number(this._state.pastPaidOut) : 0;
  }

  get pastWinCount() {
    return this._state ? Number(this._state.pastWinCount) : 0;
  }

  get pastLossCount() {
    return this._state ? Number(this._state.pastLossCount) : 0;
  }

  get pastRewardsEarned() {
    // IN BASE
    return this._state ? Number(this._state.pastRewardsEarned) : 0;
  }

  get pastRewardsForfeit() {
    return this._state ? Number(this._state.pastRewardsForfeit) : 0;
  }

  get pastRewardsClaimed() {
    return this._state ? Number(this._state.pastRewardsClaimed) : 0;
  }

  get formattedPastRewards() {
    const rewardTokenDecimals =
      this.platform.rewardTokenConfig?.houseToken.decimals || 6;

    return {
      pastRewardsEarned:
        this.pastRewardsEarned / Math.pow(10, rewardTokenDecimals),
      pastRewardsClaimed:
        this.pastRewardsClaimed / Math.pow(10, rewardTokenDecimals),
      pastRewardsForfeit:
        this.pastRewardsForfeit / Math.pow(10, rewardTokenDecimals),
    };
  }

  get instanceNonce() {
    return this._state ? this._state.instanceNonce : new anchor.BN(0);
  }

  get created() {
    return this._state ? new Date(Number(this._state.created) * 1000) : null;
  }

  get lastActivity() {
    return this._state
      ? new Date(Number(this._state.lastActivity) * 1000)
      : null;
  }

  get excludeUntil() {
    return this._state != null &&
      this._state.excludeUntil != null &&
      this.isPermanentlySelfExcluded == false
      ? new Date(this._state.excludeUntil.toNumber() * 1000)
      : null;
  }

  get isCurrentlySelfExcluded() {
    return (
      this.state != null &&
      this.isPermanentlySelfExcluded == false &&
      this.excludeUntil != null &&
      this.excludeUntil > new Date()
    );
  }

  get isPermanentlySelfExcluded() {
    return (
      this._state != null &&
      this._state.excludeUntil?.toString() == MAX_DATE_BN.toString()
    ); // i64::MAX
  }

  get referrerPubkey() {
    return this._state ? this._state.referrer : null;
  }

  get teamPubkey() {
    return this._state
      ? this._state.team.toString() != SystemProgram.programId.toString()
        ? this._state.team
        : null
      : null;
  }

  get syncedStateBenefits() {
    if (this.state == null) {
      return;
    }

    if (this.rewardsAreOutOfSync) {
      // SOLVE FOR THE REWARDS USING VALUE FROM PLATFORM RANKS
      const rankBenefits = this.platform.platformRanks[this.rank].benefit;
      const playerBenefits = this.state.benefits.v0;

      return {
        ...playerBenefits,
        dailyBonusAccrualRatePerThousand:
          rankBenefits.dailyBonusAccrualRatePerThousand,
        weeklyBonusAccrualRatePerThousand:
          rankBenefits.weeklyBonusAccrualRatePerThousand,
        monthlyBonusAccrualRatePerThousand:
          rankBenefits.monthlyBonusAccrualRatePerThousand,
        levelUpBonusAccrualRatePerThousand:
          rankBenefits.levelUpBonusAccrualRatePerThousand,
        rakebackBaseRatePerThousand:
          rankBenefits.defaultRakebackRatePerThousand,
        rakebackBoostOnClaimPerThousand: rankBenefits.rakebackBoostPerThousand,
        rakebackBoostOnClaimDurationSeconds:
          rankBenefits.rakebackBoostDurationSeconds,
      };
    }

    return this.state.benefits.v0;
  }

  get stateBenefits() {
    return this._state ? this.syncedStateBenefits : null;
  }

  get defaultRakebackRate() {
    return this.stateBenefits
      ? this.stateBenefits.rakebackBaseRatePerThousand / 1000
      : 0;
  }

  get rakebackBoostEnds() {
    if (this.stateBenefits) {
      let date = new Date(Number(this.stateBenefits.rakebackBoostUntil) * 1000);
      if (date > new Date()) {
        return date;
      }

      return null;
    }
    return null;
  }

  get rakebackBoostRate() {
    if (
      this.stateBenefits != null &&
      this.rakebackBoostEnds &&
      this.rakebackBoostEnds > new Date()
    ) {
      return this.stateBenefits.rakebackBoostPerThousand / 1000;
    }

    return 0;
  }

  get rakebackCurrentlyActive() {
    return this.rakebackBoostRate != null && this.rakebackBoostRate > 0;
  }

  get rakebackAddOnForNFT() {
    return this.stateBenefits
      ? this.stateBenefits.rakebackNftAddOnPerThousand / 1000
      : 0;
  }

  get rakebackAddOnForTokenStaking() {
    return this.stateBenefits
      ? this.stateBenefits.rakebackStakingAddOnPerThousand / 1000
      : 0;
  }

  get currentRakebackRateAfterBoost() {
    if (this._state) {
      let uncapped =
        this.defaultRakebackRate +
        this.rakebackAddOnForNFT +
        this.rakebackAddOnForTokenStaking;
      return Math.min(uncapped, this.platform.maxRakebackRate || 0);
    }
    return 0;
  }

  get currentRakebackRate() {
    let uncapped = this.currentRakebackRateAfterBoost + this.rakebackBoostRate;
    return Math.min(uncapped, this.platform.maxRakebackRate || 0);
  }

  get dailyBonusAccrualRate() {
    return this.stateBenefits
      ? this.stateBenefits.dailyBonusAccrualRatePerThousand / 1000
      : 0;
  }

  get weeklyBonusAccrualRate() {
    return this.stateBenefits
      ? this.stateBenefits.weeklyBonusAccrualRatePerThousand / 1000
      : 0;
  }

  get monthlyBonusAccrualRate() {
    return this.stateBenefits
      ? this.stateBenefits.monthlyBonusAccrualRatePerThousand / 1000
      : 0;
  }

  get levelUpBonusAccrualRate() {
    return this.stateBenefits
      ? this.stateBenefits.levelUpBonusAccrualRatePerThousand / 1000
      : 0;
  }

  get rakebackBoostOnClaimRate() {
    return this.stateBenefits
      ? this.stateBenefits.rakebackBoostOnClaimPerThousand / 1000
      : 0;
  }

  get rakebackBoostOnClaimDurationSeconds() {
    return this.stateBenefits
      ? this.stateBenefits.rakebackBoostOnClaimDurationSeconds
      : 0;
  }

  get benefits(): IBenefitRate | undefined {
    if (this._stateLoaded == false) {
      return;
    }

    return {
      rakebackBaseRate: this.defaultRakebackRate,
      rakebackBoost: this.rakebackBoostRate,
      rakebackBoostUntil: this.rakebackBoostEnds,
      rakebackNftAddOn: this.rakebackAddOnForNFT,
      rakebackStakingAddOn: this.rakebackAddOnForTokenStaking,
      dailyBonusAccrualRate: this.dailyBonusAccrualRate,
      weeklyBonusAccrualRate: this.weeklyBonusAccrualRate,
      monthlyBonusAccrualRate: this.monthlyBonusAccrualRate,
      levelUpBonusAccrualRate: this.levelUpBonusAccrualRate,
      rakebackBoostOnClaim: this.rakebackBoostOnClaimRate,
      rakebackBoostOnClaimDurationSeconds:
        this.rakebackBoostOnClaimDurationSeconds,
    };
  }

  get sevenDayRollingWagered() {
    return this._sevenDayRollingWagered;
  }

  get impliedForfeits() {
    return this._impliedForfeits;
  }

  get impliedRakeback() {
    return this._impliedRakeback;
  }

  get impliedDailyBonus() {
    return this._impliedDailyBonus;
  }

  get impliedWeeklyBonus() {
    return this._impliedWeeklyBonus;
  }

  get impliedMonthlyBonus() {
    return this._impliedMonthlyBonus;
  }

  get impliedLevelUpBonus() {
    return this._impliedLevelUpBonus;
  }

  get houseTermsVersion() {
    return this._state != null ? this._state.houseTermsVersion : null;
  }

  get platformTermsVersion() {
    return this._state != null ? this._state.platformTermsVersion : null;
  }

  get pastBetCount() {
    return this.pastLossCount + this.pastWinCount;
  }

  get maxReferralSchemes() {
    return this.state != null ? this.state.maxReferralSchemes : null;
  }

  get referralSchemeCount() {
    return this.state != null ? this.state.referralSchemeCount : null;
  }

  solveImpliedStates() {
    this._impliedRakeback = null;
    this._impliedDailyBonus = null;
    this._impliedWeeklyBonus = null;
    this._impliedMonthlyBonus = null;
    this._impliedLevelUpBonus = null;
    this._impliedForfeits = [];
    this.solveSevenDayRollingWagered();
    this.solveImpliedRewardInfos();
    this.solveImpliedForfeits();
  }

  solveSevenDayRollingWagered() {
    if (this._state) {
      const nowTs = Date.now() / 1_000;
      const lastRefreshTs = Number(this._state.lastRollingRefresh);
      const unorderedUnprocessedSevenDays =
        this._state.sevenDayRollingWagered.map((v) => {
          return Number(v);
        });
      var unorderedSevenDays: number[] = [0, 0, 0, 0, 0, 0, 0];
      var orderedSevenDays: { value: number; timestamp: Date }[] = [];
      const currentDayInt = Math.floor(nowTs / UNIX_DAY_IN_SECONDS);
      const currentDayStart = currentDayInt * UNIX_DAY_IN_SECONDS;
      const sevenDaysAgoStart = currentDayStart - 7 * UNIX_DAY_IN_SECONDS;
      const currentDaySlotIdx = currentDayInt % 7;
      const slotIdxs = [0, 1, 2, 3, 4, 5, 6];

      if (lastRefreshTs >= currentDayStart) {
        // Nothing to do, assume it's been done for today already before
        unorderedSevenDays = unorderedUnprocessedSevenDays;
      } else if (lastRefreshTs < sevenDaysAgoStart) {
        // Clear all, since they're all more than 7 days old
        unorderedSevenDays = [0, 0, 0, 0, 0, 0, 0];
      } else {
        // For each slot, if it started AFTER the last_rolling_refresh,
        // assume it's stale and overwrite with 0
        const daysAgoArray = slotIdxs.map(
          (si) => (currentDaySlotIdx + 7 - si) % 7,
        );
        const startOfDayArray = daysAgoArray.map(
          (da) => currentDayStart - da * UNIX_DAY_IN_SECONDS,
        );
        startOfDayArray.forEach((v, i) => {
          unorderedSevenDays[i] =
            startOfDayArray[i] > lastRefreshTs
              ? 0
              : unorderedUnprocessedSevenDays[i];
        });
      }
      // Reorder

      slotIdxs.forEach((v) => {
        // WANT TO ADD A TIMESTAMP OF THE START OF THE RELATED DAY ASWEL AS VALUE

        orderedSevenDays.push({
          timestamp: new Date(
            (currentDayStart - v * UNIX_DAY_IN_SECONDS) * 1000,
          ),
          value: unorderedSevenDays[(7 + currentDaySlotIdx - v) % 7],
        });
      });

      this._sevenDayRollingWagered = orderedSevenDays;
    } else {
      const nowTs = Date.now() / 1_000;
      var unorderedSevenDays: number[] = [0, 0, 0, 0, 0, 0, 0];
      var orderedSevenDays: { value: number; timestamp: Date }[] = [];
      const currentDayInt = Math.floor(nowTs / UNIX_DAY_IN_SECONDS);
      const currentDayStart = currentDayInt * UNIX_DAY_IN_SECONDS;
      const currentDaySlotIdx = currentDayInt % 7; // relative to genesis
      const slotIdxs = [0, 1, 2, 3, 4, 5, 6]; // thu -> wed

      slotIdxs.forEach((v) => {
        // WANT TO ADD A TIMESTAMP OF THE START OF THE RELATED DAY ASWEL AS VALUE

        orderedSevenDays.push({
          timestamp: new Date(
            (currentDayStart - v * UNIX_DAY_IN_SECONDS) * 1000,
          ),
          value: unorderedSevenDays[(7 + currentDaySlotIdx - v) % 7],
        });
      });

      this._sevenDayRollingWagered = orderedSevenDays;
    }
  }

  solveImpliedRewardInfos() {
    if (this._state) {
      this._impliedRakeback = getRewardInfoFromSlots(
        this._state.dailyRakebackAlternating,
        Number(this._state.lastRewardRefresh),
        Math.floor(Date.now() / 1_000),
        RewardType.Rakeback,
        this,
      );
      this._impliedDailyBonus = getRewardInfoFromSlots(
        this._state.dailyBonusAlternating,
        Number(this._state.lastRewardRefresh),
        Math.floor(Date.now() / 1_000),
        RewardType.DailyBonus,
        this,
      );
      this._impliedWeeklyBonus = getRewardInfoFromSlots(
        this._state.weeklyBonusAlternating,
        Number(this._state.lastRewardRefresh),
        Math.floor(Date.now() / 1_000),
        RewardType.WeeklyBonus,
        this,
      );
      this._impliedMonthlyBonus = getRewardInfoFromSlots(
        this._state.monthlyBonusAlternating,
        Number(this._state.lastRewardRefresh),
        Math.floor(Date.now() / 1_000),
        RewardType.MonthlyBonus,
        this,
      );
      // TODO: LevelUp Bonus
      this._impliedLevelUpBonus = getRewardInfoFromSlots(
        this._state.levelUpBonusAlternating,
        Number(this._state.lastRewardRefresh),
        Math.floor(Date.now() / 1_000),
        RewardType.LevelUpBonus,
        this,
      );
    }
    // Otherwise don't overwrite the null values
  }

  solveImpliedForfeits() {
    if (this._state) {
      var listOfForfeits: ImpliedRewardForfeit[] = [];
      listOfForfeits.push(...this._impliedRakeback.impliedForfeits);
      listOfForfeits.push(...this._impliedDailyBonus.impliedForfeits);
      listOfForfeits.push(...this._impliedWeeklyBonus.impliedForfeits);
      listOfForfeits.push(...this._impliedMonthlyBonus.impliedForfeits);
      listOfForfeits.push(...this._impliedLevelUpBonus.impliedForfeits);
      this._impliedForfeits = listOfForfeits.sort(
        (a, b) => Number(a.timestamp) - Number(b.timestamp),
      );
    }
    // Otherwise don't overwrite the empty list
  }

  get collectable(): ICollectibleBenefits | undefined {
    if (this._stateLoaded == false) {
      return;
    }

    const baseTokenDecimals =
      this.platform.house.getBaseTokenConfigAndStatistics().decimals;

    const rakeback = formatImpliedReward(
      this.impliedRakeback,
      baseTokenDecimals,
    );
    const daily = formatImpliedReward(
      this.impliedDailyBonus,
      baseTokenDecimals,
    );
    const weekly = formatImpliedReward(
      this.impliedWeeklyBonus,
      baseTokenDecimals,
    );
    const monthly = formatImpliedReward(
      this.impliedMonthlyBonus,
      baseTokenDecimals,
    );
    const levelUp = formatImpliedReward(
      this.impliedLevelUpBonus,
      baseTokenDecimals,
    );

    return {
      rakeback: rakeback,
      daily: daily,
      weekly: weekly,
      monthly: monthly,
      levelUp: levelUp,
      collectable: null,
      totalAccruing:
        (levelUp?.amountCurrentlyAccruingUi || 0) +
        (rakeback?.amountCurrentlyAccruingUi || 0) +
        (daily?.amountCurrentlyAccruingUi || 0) +
        (weekly?.amountCurrentlyAccruingUi || 0) +
        (monthly?.amountCurrentlyAccruingUi || 0),
      totalCollectible:
        (levelUp?.amountAvailableToCollectUi || 0) +
        (rakeback?.amountAvailableToCollectUi || 0) +
        (daily?.amountAvailableToCollectUi || 0) +
        (weekly?.amountAvailableToCollectUi || 0) +
        (monthly?.amountAvailableToCollectUi || 0),
    };
  }

  get rewardCalendars() {
    return this._rewardCalendars;
  }

  get rewardCalendar() {
    return this.rewardCalendars != null ? this.rewardCalendars[0] : null;
  }

  async initializePlayerAccountIxn(
    username: string,
    houseTermsVersionAccepted: number,
    platformTermsVersionAccepted: number,
    referrer?: PublicKey,
    owner: PublicKey = this.program.provider.publicKey,
  ) {
    const username_20_bytes = Player.serializeUsernameToBytes20(username);
    const usernameAccountPubkey = this.deriveUsernameAccountPubkey(username);
    const referrerPubkey = referrer != null ? referrer : this.publicKey; // refer to own player account pubkey to indicate none

    const ixn = await this.house.program.methods
      .initializePlayer({
        username: username_20_bytes,
        houseTermsVersionAccepted: houseTermsVersionAccepted,
        platformTermsVersionAccepted: platformTermsVersionAccepted,
      })
      .accounts({
        house: this.house.publicKey,
        platform: this.platform.publicKey,
        platformRanks: this.platform.derivePlatformRanksPubkey(),
        platformPayer: this.platform.derivePlatformPayerPubkey(),
        owner: owner,
        player: this.publicKey,
        username: usernameAccountPubkey,
        referrer: referrerPubkey,
        systemProgram: anchor.web3.SystemProgram.programId,
      })
      .instruction();
    return ixn;
  }

  async updatePlayerIxn(
    newUsername: string,
    owner: PublicKey,
    hidden?: boolean,
    excludeUntil?: anchor.BN,
    avatar?: string, // CHECK NFT HERE
  ) {
    const new_username_20_bytes =
      Player.serializeUsernameToBytes20(newUsername);

    const oldUsernameAccountPubkey = this.deriveUsernameAccountPubkey(
      this.username || "",
    );
    const newUsernameAccountPubkey =
      this.deriveUsernameAccountPubkey(newUsername);

    const updateArgs = {
      username: new_username_20_bytes,
      hidden: hidden != null ? hidden : false,
      excludeUntil: excludeUntil != null ? excludeUntil : null,
      avatar: avatar != null ? avatar : null,
    };

    const ixn = await this.house.program.methods
      .playerUpdate(updateArgs)
      .accounts({
        house: this.house.publicKey,
        platform: this.platform.publicKey,
        platformPayer: this.platform.derivePlatformPayerPubkey(),
        owner: owner,
        player: this.publicKey,
        oldUsername: oldUsernameAccountPubkey,
        newUsername: newUsernameAccountPubkey,
        systemProgram: anchor.web3.SystemProgram.programId,
      })
      .instruction();
    return ixn;
  }

  async createAssociatedTokenAccountIxn(
    tokenMintPubkey: PublicKey,
    owner: PublicKey,
  ) {
    return createAssociatedTokenAccountInstruction(
      owner,
      await this.deriveTokenAccountPubkey(tokenMintPubkey),
      owner,
      tokenMintPubkey,
      TOKEN_PROGRAM_ID,
      ASSOCIATED_TOKEN_PROGRAM_ID,
    );
  }

  async requestTokenAirdropIxn(tokenMintPubkey: PublicKey, amount: number) {
    const userTokenAccountPubkey =
      await this.house.derivePlayerAssociatedTokenAccount(
        this.ownerPubkey,
        tokenMintPubkey,
      );
    const houseTokenVaultPubkey =
      await this.house.deriveHouseTokenVault(tokenMintPubkey);

    return await this.house.program.methods
      .airdrop({
        amount: new anchor.BN(amount),
      })
      .accounts({
        owner: this.ownerPubkey,
        house: this.house.publicKey,
        player: this.publicKey,
        tokenMint: tokenMintPubkey,
        tokenAccount: userTokenAccountPubkey,
        vault: houseTokenVaultPubkey,
        tokenProgram: TOKEN_PROGRAM_ID,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        systemProgram: anchor.web3.SystemProgram.programId,
      })
      .instruction();
  }

  async close(
    confirmationLevel: anchor.web3.Commitment = "processed",
    onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
    onSuccessfulConfirmCallback?: Function, // callbackFn(txnHash);
    onErrorCallback?: Function, // callbackFn(err);
  ) {
    try {
      const usernameAccountPubkey = this.deriveUsernameAccountPubkey(
        this.username,
      );

      const tx = await this.program.methods
        .closePlayer({})
        .accounts({
          //owner: this.ownerPubkey,
          platform: this.platform.publicKey,
          platformPayer: this.platform.derivePlatformPayerPubkey(),
          player: this.publicKey,
          username: usernameAccountPubkey,
          systemProgram: anchor.web3.SystemProgram.programId,
        })
        .rpc();

      if (onSuccessfulSendCallback) {
        onSuccessfulSendCallback(tx);
      }

      listenForTransaction(
        this.program.provider.connection,
        tx,
        confirmationLevel,
        onSuccessfulConfirmCallback,
        onErrorCallback,
      );

      this.loadState();
    } catch (err) {
      if (onErrorCallback) {
        onErrorCallback(err);
      } else {
        console.error(err);
      }
    }
  }

  static async fetchAllPlayersForPlatform(
    platform: Platform,
  ): Promise<Player[]> {
    // i.e. get all of the players who have been referred by this scheme
    const playerPubkeyAndBuffers =
      await platform.program.provider.connection.getProgramAccounts(
        platform.program.programId,
        {
          filters: [
            {
              memcmp: {
                offset: 0, // Anchor account discriminator for Player type
                bytes: base58.encode(Player.derivePlayerAccountDiscriminator()),
              },
            },
            {
              memcmp: {
                offset: 72, // 8 (discriminator) + 32 (owner) + 32 (house)
                bytes: base58.encode(platform.publicKey.toBuffer()),
              },
            },
          ],
        },
      );
    const players = playerPubkeyAndBuffers.map((playerPubkeyAndBuffer) =>
      Player.loadFromBuffer(
        platform,
        playerPubkeyAndBuffer.pubkey,
        playerPubkeyAndBuffer.account.data,
      ),
    );
    return players;
  }

  static async loadMultiple(
    playerPubkeys: PublicKey[],
    platform: Platform,
  ): Promise<Player[]> {
    const playerBuffers =
      await platform.program.account.player.fetchMultiple(playerPubkeys);

    return playerBuffers.reduce((result, item, index) => {
      if (item == null) {
        return result;
      }

      const player = Player.loadFromState(platform, item.owner, item);
      result.push(player);

      return result;
    }, new Array<Player>());
  }

  static async loadMultipleByPubkey(
    playerPubkeys: PublicKey[],
    platform: Platform,
  ): Promise<Map<string, Player>> {
    const players = await Player.loadMultiple(playerPubkeys, platform);

    return players.reduce((result, item, index) => {
      const playerPubkey = playerPubkeys[index];

      if (item == null || playerPubkey == null) {
        return result;
      }

      result.set(playerPubkey.toString(), item);

      return result;
    }, new Map<string, Player>());
  }
}
