import Icon from "../../components/common/icon/Icon";
import DownIcon from "../../assets/icons/arrow-down.svg";
import { IMergedToken } from "../../contexts/AggregatedBalancesContext";
import {
  NumberType,
  formatZeebitNumber,
} from "../../utils/currency/formatting";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

interface IDropdown {
  tokens: IMergedToken[];
  selectedToken: IMergedToken | undefined;
  updateSelectedToken: Function;
}
export const Dropdown = ({
  tokens,
  selectedToken,
  updateSelectedToken,
}: IDropdown) => {
  return (
    <Menu
      as="div"
      className="relative flex flex-col items-center gap-y-3 self-stretch z-20"
    >
      <Menu.Button
        as="div"
        className="flex py-5 px-4 items-center gap-x-3 self-stretch rounded-md border-2 border-solid border-brand-purple-normal bg-gray-700"
      >
        <SelectedToken selectedToken={selectedToken} />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items as="div" className="absolute min-w-full top-[80px] right-0">
          <div className="flex flex-col py-1.5 px-0 items-start rounded-lg bg-gray-700 shadow-brand-regular">
            {tokens.map((token, index) => {
              return (
                <DropdownToken
                  key={index}
                  token={token}
                  updateSelectedToken={updateSelectedToken}
                />
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

interface ISelectedToken {
  selectedToken: IMergedToken | undefined;
}

const SelectedToken = ({ selectedToken }: ISelectedToken) => {
  const formatVal = formatZeebitNumber(
    selectedToken?.wallet?.uiAmount || 0,
    NumberType.TOKEN_AMOUNT,
    undefined,
    selectedToken?.house?.decimals || selectedToken?.context?.decimals || 9,
  );

  // TODO - CHECK WHY SVG BEING OVERWRITTEN BTC/USDC
  // const icon = <SVG src={token?.context?.imageDarkSvg || ''} width={16} height={16} />;
  const iconPng = (
    <img
      src={selectedToken?.context?.imageDarkPng || ""}
      className="w-[16px] h-[16px]"
    />
  );

  return (
    <Fragment>
      <div className="flex p-1 justify-between items-center flex-1">
        <div className="flex items-center gap-x-3">
          <div>{iconPng}</div>
          <div>{`${selectedToken?.context?.symbol}`}</div>
        </div>
        <div className="flex flex-col justify-center items-end gap-x-[-2px]">
          {formatVal}
        </div>
      </div>
      <Icon icon={<DownIcon />} />
    </Fragment>
  );
};

interface IDropdownToken {
  token: IMergedToken;
  updateSelectedToken: Function;
}

const DropdownToken = ({ token, updateSelectedToken }: IDropdownToken) => {
  const formatVal = formatZeebitNumber(
    token?.wallet?.uiAmount || 0,
    NumberType.TOKEN_AMOUNT,
    undefined,
    token?.context?.decimals || 9,
  );
  // TODO - CHECK WHY SVG BEING OVERWRITTEN BTC/USDC
  // const icon = <SVG src={token?.context?.imageDarkSvg || ''} width={16} height={16} />;
  const iconPng = (
    <img
      src={token?.context?.imageDarkPng || ""}
      className="w-[16px] h-[16px]"
    />
  );

  return (
    <Menu.Item
      as="div"
      className="cursor-pointer flex p-3 justify-between items-center self-stretch bg-gray-700 hover:bg-gray-500"
      onClick={() => {
        updateSelectedToken(token);
      }}
    >
      <div className="flex px-1 py-0 items-center gap-x-3">
        <div>{iconPng}</div>
        <div>{token?.context?.symbol}</div>
      </div>
      <div>{formatVal}</div>
    </Menu.Item>
  );
};
