import { PublicKey } from "@solana/web3.js";

import { TOKEN_MINT_PUBKEY } from "../sdk/constants";
import { NetworkType, defaultNetwork } from "../utils/chain/network";

export const USDC_MAINNET = new PublicKey(
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
);
export const USDC_DEVNET = TOKEN_MINT_PUBKEY;

export const USDC_MINT =
  defaultNetwork == NetworkType.DEVNET ? USDC_DEVNET : USDC_MAINNET;
export const USDC_DECIMALS = 6;
export const SOL_DECIMALS = 9;

export const SOL_TOKEN_PUBKEY = new PublicKey(
  "So11111111111111111111111111111111111111112",
);
