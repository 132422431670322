import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
} from "react";
import { ThemeType, defaultThemeType } from "../types/theme";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { AverLanguage, defaultLanguage } from "../types/language";
import { AverSound, defaultAverSound } from "../types/sound";

interface IBrowserPreference {
  theme: ThemeType;
  setTheme: Dispatch<SetStateAction<ThemeType>>;
  language: AverLanguage;
  setLanguage: Dispatch<SetStateAction<AverLanguage>>;
  playSound: AverSound;
  setPlaySound: Dispatch<SetStateAction<AverSound>>;
}

export const BrowserPreferenceContext = createContext<IBrowserPreference>(
  {} as IBrowserPreference,
);

interface Props {
  children: any;
}

export const BrowserPreferenceProvider = (props: Props) => {
  const [theme, setTheme] = useLocalStorage("aver-theme", defaultThemeType);
  const [language, setLanguage] = useLocalStorage(
    "aver-language",
    defaultLanguage,
  );
  const [playSound, setPlaySound] = useLocalStorage(
    "aver-sound",
    defaultAverSound,
  );

  useEffect(() => {
    const themeToRemove =
      theme == ThemeType.DARK ? ThemeType.LIGHT : ThemeType.DARK;

    try {
      document.documentElement.classList.remove(themeToRemove);
    } catch (e) {
      console.warn("Error setting the theme.", e);
    }

    document.documentElement.classList.add(theme);
  }, [theme]);

  return (
    <BrowserPreferenceContext.Provider
      value={useMemo(
        () => ({
          theme,
          setTheme,
          language,
          setLanguage,
          playSound,
          setPlaySound,
        }),
        [theme, language, playSound],
      )}
    >
      {props.children}
    </BrowserPreferenceContext.Provider>
  );
};
