import { createContext, useMemo, useState } from "react";

export interface IModalContext {
  walletModalOpen: boolean;
  setWalletModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  walletManagerModalOpen: boolean;
  setWalletManagerModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  registrationModalOpen: boolean;
  setRegistrationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalContext = createContext<IModalContext>({} as IModalContext);

interface Props {
  children: any;
}

export const ModalProvider = ({ children }: Props) => {
  const [walletModalOpen, setWalletModalOpen] = useState<boolean>(false);
  const [registrationModalOpen, setRegistrationModalOpen] =
    useState<boolean>(false);
  const [walletManagerModalOpen, setWalletManagerModalOpen] =
    useState<boolean>(false);

  return (
    <ModalContext.Provider
      value={useMemo(
        () => ({
          walletModalOpen: walletModalOpen,
          setWalletModalOpen: setWalletModalOpen,
          registrationModalOpen: registrationModalOpen,
          setRegistrationModalOpen: setRegistrationModalOpen,
          walletManagerModalOpen: walletManagerModalOpen,
          setWalletManagerModalOpen: setWalletManagerModalOpen,
        }),
        [walletModalOpen, registrationModalOpen, walletManagerModalOpen],
      )}
    >
      {children}
    </ModalContext.Provider>
  );
};
