import { createContext, useContext, useEffect, useMemo } from "react";
import { PublicKey } from "@solana/web3.js";
import { ISolanaRpc } from "../utils/solana/rpc";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import { Wallet, useWallet } from "@solana/wallet-adapter-react";
import NonSocialSolanaRpc from "../utils/solana/rpc-non-social";
import { NetworkContext } from "./NetworkContext";
import { WalletName } from "@solana/wallet-adapter-base";

export interface IWrappedWalletContext {
  // SOLANA
  loggedIn: boolean;
  connect: (wallet: string, provider?: string) => Promise<void>;
  disconnect: () => Promise<void>;
  solanaRpc: ISolanaRpc | undefined;
  walletPubkey: PublicKey | null;
  wallets: Wallet[];
  select: (walletName: WalletName | null) => void;
  wallet: Wallet | null;
  connected: boolean;
  connecting: boolean;
}

export const WrappedWalletContext = createContext<IWrappedWalletContext>(
  {} as IWrappedWalletContext,
);

interface Props {
  children: any;
}

export const WrappedWalletProvider = ({ children }: Props) => {
  const { walletValidation } = useContext(ErrorHandlingContext);

  const nonSocialWallet = useWallet();

  // CHECK WALLET CONNECTED - Change label of button and disable button (Connect to bet)
  useEffect(() => {
    if (nonSocialWallet.publicKey == null) {
      walletValidation.addErrorMessage({
        type: ErrorType.WALLET_NOT_CONNECTED,
        title: "Wallet Not Connected",
        message: "You must connect your wallet place a bet.",
      });
    } else {
      walletValidation.removeErrorMessage(ErrorType.WALLET_NOT_CONNECTED);
    }
  }, [nonSocialWallet.publicKey]);

  const { client } = useContext(NetworkContext);
  const rpc = useMemo(() => {
    if (nonSocialWallet.publicKey != null && client != null) {
      // WANT TO RETURN THE RPC CLASS
      return new NonSocialSolanaRpc(nonSocialWallet, client);
    }
  }, [nonSocialWallet.publicKey, client]);

  return (
    <WrappedWalletContext.Provider
      value={useMemo(
        () => ({
          loggedIn: nonSocialWallet.connected,
          solanaRpc: rpc,
          walletPubkey: nonSocialWallet.publicKey,
          wallets: nonSocialWallet.wallets,
          select: nonSocialWallet.select,
          wallet: nonSocialWallet.wallet,
          disconnect: nonSocialWallet.disconnect,
          connect: nonSocialWallet.connect,
          connected: nonSocialWallet.connected,
          connecting: nonSocialWallet.connecting,
        }),
        [
          nonSocialWallet.connect,
          nonSocialWallet.disconnect,
          rpc,
          nonSocialWallet.publicKey,
          nonSocialWallet.connected,
          nonSocialWallet.wallets,
          nonSocialWallet.select,
          nonSocialWallet.wallet,
          nonSocialWallet.connecting,
        ],
      )}
    >
      {children}
    </WrappedWalletContext.Provider>
  );
};
