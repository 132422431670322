import { useContext, useEffect, useState } from "react";

import { IPlayerMeta, PlayerContext } from "../contexts/PlayerContext";
import Player from "../sdk/playerAccount";
import { useModal } from "react-modal-hook";
import House from "../sdk/house";
import Platform from "../sdk/platform";
import { HouseContext } from "../contexts/HouseContext";
import { PlatformContext } from "../contexts/PlatformContext";
import { RegistrationModal } from "../modals/RegistrationModal";
import { WrappedWalletContext } from "../contexts/WrappedWalletContext";

export function useOnboardingHandler() {
  const { playerAccount, playerMeta, setPlayerMeta } =
    useContext(PlayerContext);
  const { house } = useContext(HouseContext);
  const { platform } = useContext(PlatformContext);
  const [currentStep, setCurrentStep] = useState(0);

  const [showRegistrationModal, hideRegistrationModal] = useModal(
    ({ in: open }) => (
      <RegistrationModal
        open={open}
        closeModal={() => {
          hideRegistrationModal();
          setCurrentStep(0);
        }}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
    ),
    [currentStep],
  );

  useEffect(() => {
    async function checkIfRegistrationNeeded(
      player: Player,
      house: House,
      platform: Platform,
      playerMeta: IPlayerMeta | undefined,
    ) {
      const hasState = await player.checkPlayerAccountInitialized();
      if (hasState == false) {
        setCurrentStep(0);
        let meta = playerMeta || {};

        if (meta.signedTerms == true) {
          return;
        }

        meta.signedTerms = false;
        setPlayerMeta(meta);

        showRegistrationModal();
      } else {
        const houseTermsVersion = player.houseTermsVersion;
        const platformTermsVersion = player.platformTermsVersion;
        const newerHouseTerms =
          houseTermsVersion == null ||
          houseTermsVersion < house.latestTermsVersion;
        const newerPlatformTerms =
          platformTermsVersion == null ||
          platformTermsVersion < (platform.latestTermsVersion || 0);

        if (newerHouseTerms == true || newerPlatformTerms == true) {
          let newMeta: IPlayerMeta = {};
          newMeta.latestHouseTerms = houseTermsVersion;
          newMeta.latestPlatformTerms = platformTermsVersion;
          newMeta.signedTerms = false;
          setPlayerMeta(newMeta);
          setCurrentStep(0);

          showRegistrationModal();
        }
      }
    }

    if (playerAccount != null && house != null && platform != null) {
      checkIfRegistrationNeeded(playerAccount, house, platform, playerMeta);
    }
  }, [playerAccount, house, platform, playerMeta]);

  // WIPE LOCAL STORAGE VALUES IF NEW WALLET IS CONNECTED
  const { walletPubkey } = useContext(WrappedWalletContext);
  useEffect(() => {
    const wallet = walletPubkey?.toString();

    if (wallet == null) {
      return;
    }

    if (playerMeta?.walletPubkey == null || playerMeta.walletPubkey != wallet) {
      setPlayerMeta({
        walletPubkey: wallet,
      });
    }
  }, [walletPubkey]);
}
