import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { DivvyPlayerPoints, DivvyUser } from "../types/divvy";
import { WrappedWalletContext } from "./WrappedWalletContext";
import { DIVVY_API_URL } from "../utils/env/env";
import { DIVVY_LEADERBOARD_POINTS_JSON_URL } from "../constants/constants";

interface IDivvyPreference {
  divvyUser: DivvyUser | undefined;
  divvyPoints: DivvyPlayerPoints[];
}

export const DivvyContext = createContext<IDivvyPreference>(
  {} as IDivvyPreference,
);

interface Props {
  children: any;
}

export const DivvyProvider = (props: Props) => {
  const { walletPubkey } = useContext(WrappedWalletContext);

  // Divvy user fingerprint
  const [divvyUser, setDivvyUser] = useState<DivvyUser>();

  // Divvy user points
  const [divvyPoints, setDivvyPoints] = useState<DivvyPlayerPoints[]>([]);

  // Fetch divvy user data
  useEffect(() => {
    const fetchDivvyUserData = async (address: string) => {
      try {
        const response = await fetch(`${DIVVY_API_URL}/users/${address}`);
        const data = await response.json();
        setDivvyUser(data.user);
      } catch (error) {
        setDivvyUser(undefined);
      }
    };

    if (!walletPubkey) {
      setDivvyUser(undefined);
      return;
    }

    fetchDivvyUserData(walletPubkey.toString());
  }, [walletPubkey]);

  // Fetch divvy points
  useEffect(() => {
    const fetchDivvyPoints = async () => {
      try {
        const response = await fetch(`${DIVVY_LEADERBOARD_POINTS_JSON_URL}`);
        const data = await response.json();
        const divvyPoints = data.map((point: DivvyPlayerPoints) => {
          return {
            ...point,
            points: point.points.toString().replaceAll(",", ""),
            lpPoints: point.lpPoints.toString().replaceAll(",", ""),
            communityPoints: point.communityPoints
              .toString()
              .replaceAll(",", ""),
            referralPoints: point.referralPoints.toString().replaceAll(",", ""),
          };
        });

        setDivvyPoints(divvyPoints);
      } catch (error) {
        setDivvyPoints([]);
      }
    };

    fetchDivvyPoints();
  }, []);

  return (
    <DivvyContext.Provider
      value={useMemo(
        () => ({
          divvyUser: divvyUser,
          divvyPoints: divvyPoints,
        }),
        [divvyUser, divvyPoints],
      )}
    >
      {props.children}
    </DivvyContext.Provider>
  );
};
