import * as anchor from "@coral-xyz/anchor";
import {
  ComputeBudgetProgram,
  PublicKey,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import House, { IHouseToken } from "./house";
import {
  listenForTransaction,
  toPlatformStatus,
  toPlatformTokenStatus,
} from "./utils";
import { PlatformTokenStatus, PlatformStatus } from "./enums";
import { PlatformRanks } from "./platformRanks";
import { IPlatformRank } from "../contexts/PlatformContext";
import { USDC_MINT } from "../constants/sol";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  NATIVE_MINT,
  createAssociatedTokenAccountInstruction,
} from "@solana/spl-token";

export interface IPlatformToken {
  pubkey: string;
  status: PlatformTokenStatus;
  statusString?: string;
  houseTokenIdx: number;
  houseToken: IHouseToken;
  revenueShareBalanceAccrued: number;
  revenueShareDrawndown: number;
  rewardAccrualBalanceAccrued: number;
  rewardAccrualDrawndown: number;
  rewardFundingBalanceAvailable: number;
  rewardFundingReserved: number;
}

export type RewardConfig = {
  isOffered: boolean;
  defaultAccrualRatePerThousand: number;
  maxAccrualRatePerThousand: number;
  upFrontClaimPerThousand: number;
  daySpreadForClaimRemainder: number;
};

export type ReferralConfig = {
  isOffered: boolean;
  defaultMaxReferralSchemesPerPlayer: number;
  defaultRatePerThousand: number;
  maxRatePerThousand: number;
  minReferredPlayersToCollect: anchor.BN;
  minReferredWagerValueToCollect: anchor.BN;
  upFrontClaimPerThousand: number;
  daySpreadForClaimRemainder: number;
};

export default class Platform {
  private _house: House;
  private _platformPubkey: PublicKey;

  private _state: any;
  private _stateLoaded: boolean;
  private _platformRanks: any[];

  constructor(house: House, platformPubkey: PublicKey) {
    this._stateLoaded = false;
    this._house = house;
    this._platformPubkey = platformPubkey;
  }

  static async load(
    house: House,
    platformPubkey: PublicKey,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    const platform = new Platform(house, platformPubkey);
    await platform.loadState(commitmentLevel);
    return platform;
  }

  async loadState(commitmentLevel: anchor.web3.Commitment = "processed") {
    const state = await this.program.account.platform.fetchNullable(
      this.publicKey,
      commitmentLevel,
    );
    this._state = state;
    this._stateLoaded = true;
    return;
  }

  setPlatformRanks(platformRanks: IPlatformRank[]) {
    this._platformRanks = platformRanks;

    return this;
  }

  get platformRanks(): IPlatformRank[] {
    return this._platformRanks;
  }

  get referralConfig(): ReferralConfig | undefined {
    return this._state?.referralConfig;
  }

  get defaultMaxReferralSchemesPerPlayer(): number | undefined {
    return this.referralConfig?.defaultMaxReferralSchemesPerPlayer;
  }

  get house() {
    return this._house;
  }

  get program() {
    return this._house.program;
  }

  get publicKey() {
    return this._platformPubkey;
  }

  get state() {
    return this._state;
  }

  get status() {
    return this._state ? toPlatformStatus(this._state.status) : null;
  }

  get listTokenMints(): PublicKey[] {
    return this._state
      ? this._state.tokens.map((tkn) =>
          this.house.getTokenPubkeyFromHouseTokenIdx(tkn.houseTokenIdx),
        )
      : null;
  }

  get listTokens(): any[] {
    return this._state
      ? this._state.tokens.map(
          (tkn) =>
            (tkn.pubkey = this.house.getTokenPubkeyFromHouseTokenIdx(
              tkn.houseTokenIdx,
            )),
        )
      : [];
  }

  get tokens(): IPlatformToken[] {
    return this._state
      ? this._state.tokens.map((token) => {
          return {
            pubkey: this.house
              .getTokenPubkeyFromHouseTokenIdx(token.houseTokenIdx)
              .toString(),
            status: toPlatformTokenStatus(token.status),
            statusString: Object.keys(token.status)[0],
            houseTokenIdx: token.houseTokenIdx,
            houseToken: this.house.tokens[token.houseTokenIdx],
            revenueShareBalanceAccrued: Number(
              token.revenueShareBalanceAccrued,
            ),
            revenueShareDrawndown: Number(token.revenueShareDrawndown),
            rewardAccrualBalanceAccrued: Number(
              token.rewardAccrualBalanceAccrued,
            ),
            rewardAccrualDrawndown: Number(token.rewardAccrualDrawndown),
            rewardFundingBalanceAvailable: Number(
              token.rewardFundingBalanceAvailable,
            ),
            rewardFundingReserved: Number(token.rewardFundingReserved),
          };
        })
      : [];
  }

  get latestTermsVersion(): number | null {
    return this._state ? this._state.latestTermsVersion : null;
  }

  get maxRakebackRate(): number | null {
    return this._state
      ? this._state.rakebackConfig.is_offered
        ? this._state.rakebackConfig.maxAccrualRatePerThousand / 1_000
        : 0
      : null;
  }

  get rewardHouseTokenIdx(): number | null {
    if (this._state) {
      let tkns = this._state.tokens.filter((t) => {
        return t.isRewardToken;
      });

      return tkns.length ? tkns[0].houseTokenIdx : null;
    }
    return null;
  }

  get rewardTokenPubkey(): PublicKey | null {
    if (this.rewardHouseTokenIdx != null) {
      return this.house.getTokenPubkeyFromHouseTokenIdx(
        this.rewardHouseTokenIdx,
      );
    } else {
      return USDC_MINT; // TODO ENSURE ONE OF THE TOKENS ARE MARKED AS REWARD
    }
  }

  get rewardTokenConfig() {
    const rewardTokenPubkey = this.rewardTokenPubkey?.toString();

    if (rewardTokenPubkey == null) {
      return;
    }

    return this.tokens.find((token) => {
      return token.pubkey == rewardTokenPubkey;
    });
  }

  get rewardHouseTokenPubkey(): PublicKey | null {
    if (this.rewardHouseTokenIdx != null) {
      return this.house.deriveHouseTokenAccountPubkey(this.rewardTokenPubkey);
    }
    return null;
  }

  static derivePlatformPayerPubkey(
    platformPubkey: PublicKey,
    programId: PublicKey,
  ): PublicKey {
    const [platformPayerPubkey, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("platform_payer"),
        platformPubkey.toBuffer(),
      ],
      programId,
    );
    return platformPayerPubkey;
  }

  derivePlatformPayerPubkey(): PublicKey {
    return Platform.derivePlatformPayerPubkey(
      this.publicKey,
      this.program.programId,
    );
  }

  static derivePlatformPermissionAccountPubkey(
    authorityOwnerPubkey?: PublicKey,
    platformPubkey?: PublicKey,
    programId?: PublicKey,
  ): PublicKey {
    const [permissionAccountPubkey, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("platform_permission"),
        platformPubkey.toBuffer(),
        authorityOwnerPubkey.toBuffer(),
      ],
      programId,
    );
    return permissionAccountPubkey;
  }

  derivePlatformPermissionAccountPubkey(
    authorityOwnerPubkey: PublicKey,
  ): PublicKey {
    return Platform.derivePlatformPermissionAccountPubkey(
      authorityOwnerPubkey
        ? authorityOwnerPubkey
        : this.program.provider.publicKey,
      this.publicKey,
      this.program.programId,
    );
  }

  async addToken(
    tokenMintPubkey: PublicKey,
    status: PlatformTokenStatus,
    isRewardToken: boolean,
    confirmationLevel: anchor.web3.Commitment = "processed",
    onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
    onSuccessfulConfirmCallback?: Function, // callbackFn(txnHash);
    onErrorCallback?: Function, // callbackFn(err);
  ) {
    try {
      const permissionPubkey = this.derivePlatformPermissionAccountPubkey(
        this.program.provider.publicKey,
      );
      const tx = await this.program.methods
        .platformAddToken({
          tokenMint: tokenMintPubkey,
          status: status,
          isRewardToken: isRewardToken,
        })
        .accounts({
          payer: this.program.provider.publicKey,
          house: this.house.publicKey,
          platform: this.publicKey,
          authority: this.program.provider.publicKey,
          authorityPermission: permissionPubkey,
          tokenMint: tokenMintPubkey,
          systemProgram: anchor.web3.SystemProgram.programId,
        })
        .signers([])
        .rpc
        // {
        //     skipPreflight: true
        // }
        ();

      if (onSuccessfulSendCallback) {
        onSuccessfulSendCallback(tx);
      }

      listenForTransaction(
        this.program.provider.connection,
        tx,
        confirmationLevel,
        onSuccessfulConfirmCallback,
        onErrorCallback,
      );
    } catch (err) {
      if (onErrorCallback) {
        onErrorCallback(err);
      } else {
        console.error(err);
      }
    }
  }

  async configurePlatform(
    status?: PlatformStatus,
    latestTermsVersion?: number,
    maxCombinedRewardAccrualRatePerThousand?: number,
    referralConfig?: ReferralConfig,
    rakebackConfig?: RewardConfig,
    dailyBonusConfig?: RewardConfig,
    weeklyBonusConfig?: RewardConfig,
    monthlyBonusConfig?: RewardConfig,
    levelUpBonusConfig?: RewardConfig,
    confirmationLevel: anchor.web3.Commitment = "processed",
    onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
    onSuccessfulConfirmCallback?: Function, // callbackFn(txnHash);
    onErrorCallback?: Function, // callbackFn(err);
  ) {
    try {
      const permissionPubkey = this.derivePlatformPermissionAccountPubkey(
        this.program.provider.publicKey,
      );
      const tx = await this.program.methods
        .platformConfig({
          status: status == undefined ? null : status,
          latestTermsVersion:
            latestTermsVersion == undefined ? null : latestTermsVersion,
          maxCombinedRewardAccrualRatePerThousand:
            maxCombinedRewardAccrualRatePerThousand == undefined
              ? null
              : maxCombinedRewardAccrualRatePerThousand,
          referralConfig: referralConfig == undefined ? null : referralConfig,
          rakebackConfig: rakebackConfig == undefined ? null : rakebackConfig,
          dailyBonusConfig:
            dailyBonusConfig == undefined ? null : dailyBonusConfig,
          weeklyBonusConfig:
            weeklyBonusConfig == undefined ? null : weeklyBonusConfig,
          monthlyBonusConfig:
            monthlyBonusConfig == undefined ? null : monthlyBonusConfig,
          levelUpBonusConfig:
            levelUpBonusConfig == undefined ? null : levelUpBonusConfig,
        })
        .accounts({
          payer: this.program.provider.publicKey,
          authority: this.program.provider.publicKey,
          house: this.house.publicKey,
          platform: this.publicKey,
          authorityPermission: permissionPubkey,
          systemProgram: anchor.web3.SystemProgram.programId,
        })
        .signers([])
        .rpc();

      if (onSuccessfulSendCallback) {
        onSuccessfulSendCallback(tx);
      }

      listenForTransaction(
        this.program.provider.connection,
        tx,
        confirmationLevel,
        onSuccessfulConfirmCallback,
        onErrorCallback,
      );
    } catch (err) {
      if (onErrorCallback) {
        onErrorCallback(err);
      } else {
        console.error(err);
      }
    }
  }

  async depositRewardTokens(
    amount: number,
    confirmationLevel: anchor.web3.Commitment = "processed",
    onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
    onSuccessfulConfirmCallback?: Function, // callbackFn(txnHash);
    onErrorCallback?: Function, // callbackFn(err);
  ) {
    try {
      const rewardTokenHouseIdx = this.rewardHouseTokenIdx;
      const rewardTokenPubkey = this.rewardTokenPubkey;

      if (rewardTokenHouseIdx == null || rewardTokenPubkey == null) {
        throw new Error("The platform does not have a reward token configured");
      }
      const oraclePubkey =
        this.house.getTokenConfigAndStatistics(rewardTokenPubkey)?.oracle;
      const tokenAccountPubkey =
        await this.house.derivePlayerAssociatedTokenAccount(
          this.program.provider.publicKey,
          rewardTokenPubkey,
        );
      const houseTokenVaultPubkey =
        await this.house.deriveHouseTokenVault(rewardTokenPubkey);
      const houseTokenPubkey =
        await this.house.deriveHouseTokenAccountPubkey(rewardTokenPubkey);
      const tx = await this.program.methods
        .platformDepositRewardTokens({
          amount: new anchor.BN(amount),
        })
        .accounts({
          owner: this.program.provider.publicKey,
          house: this.house.publicKey,
          houseToken: houseTokenPubkey,
          platform: this.publicKey,
          tokenMint: rewardTokenPubkey,
          tokenAccount: tokenAccountPubkey,
          vault: houseTokenVaultPubkey,
          oracle: oraclePubkey,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: anchor.web3.SystemProgram.programId,
        })
        .signers([])
        .rpc();

      if (onSuccessfulSendCallback) {
        onSuccessfulSendCallback(tx);
      }

      listenForTransaction(
        this.program.provider.connection,
        tx,
        confirmationLevel,
        onSuccessfulConfirmCallback,
        onErrorCallback,
      );
    } catch (err) {
      if (onErrorCallback) {
        onErrorCallback(err);
      } else {
        console.error(err);
      }
    }
  }

  async depositRewardTokensTx(
    owner: PublicKey,
    amount: number,
  ): Promise<Transaction> {
    const rewardTokenHouseIdx = this.rewardHouseTokenIdx;
    const rewardTokenPubkey = this.rewardTokenPubkey;

    if (rewardTokenHouseIdx == null || rewardTokenPubkey == null) {
      throw new Error("The platform does not have a reward token configured");
    }
    const oraclePubkey =
      this.house.getTokenConfigAndStatistics(rewardTokenPubkey)?.oracle;
    const tokenAccountPubkey =
      await this.house.derivePlayerAssociatedTokenAccount(
        owner,
        rewardTokenPubkey,
      );
    const houseTokenVaultPubkey =
      await this.house.deriveHouseTokenVault(rewardTokenPubkey);
    const houseTokenPubkey =
      await this.house.deriveHouseTokenAccountPubkey(rewardTokenPubkey);
    return await this.program.methods
      .platformDepositRewardTokens({
        amount: new anchor.BN(amount),
      })
      .accounts({
        owner: owner,
        house: this.house.publicKey,
        houseToken: houseTokenPubkey,
        platform: this.publicKey,
        tokenMint: rewardTokenPubkey,
        tokenAccount: tokenAccountPubkey,
        vault: houseTokenVaultPubkey,
        oracle: oraclePubkey,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: anchor.web3.SystemProgram.programId,
      })
      .transaction();
  }

  async withdrawRewardTokens(
    amount: number,
    tokenMintPubkey?: PublicKey,
    confirmationLevel: anchor.web3.Commitment = "processed",
    onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
    onSuccessfulConfirmCallback?: Function, // callbackFn(txnHash);
    onErrorCallback?: Function, // callbackFn(err);
  ) {
    try {
      var rewardTokenPubkey: PublicKey;
      if (!tokenMintPubkey) {
        const rewardTokenHouseIdx = this.rewardHouseTokenIdx;
        rewardTokenPubkey = this.rewardTokenPubkey;
        if (rewardTokenHouseIdx == null || rewardTokenPubkey == null) {
          throw new Error(
            "The platform does not have a reward token configured",
          );
        }
      } else {
        rewardTokenPubkey = tokenMintPubkey;
      }
      const oraclePubkey =
        this.house.getTokenConfigAndStatistics(rewardTokenPubkey)?.oracle;
      const tokenAccountPubkey =
        await this.house.derivePlayerAssociatedTokenAccount(
          this.program.provider.publicKey,
          rewardTokenPubkey,
        );
      const houseTokenVaultPubkey =
        await this.house.deriveHouseTokenVault(rewardTokenPubkey);
      const houseTokenPubkey =
        await this.house.deriveHouseTokenAccountPubkey(rewardTokenPubkey);
      const permissionPubkey = this.derivePlatformPermissionAccountPubkey(
        this.program.provider.publicKey,
      );
      const tx = await this.program.methods
        .platformWithdrawRewardTokens({
          amount: new anchor.BN(amount),
        })
        .accounts({
          authority: this.program.provider.publicKey,
          authorityPermission: permissionPubkey,
          house: this.house.publicKey,
          houseToken: houseTokenPubkey,
          platform: this.publicKey,
          tokenMint: rewardTokenPubkey,
          tokenAccountOrWallet: tokenAccountPubkey,
          vault: houseTokenVaultPubkey,
          oracle: oraclePubkey,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: anchor.web3.SystemProgram.programId,
        })
        .signers([])
        .rpc();

      if (onSuccessfulSendCallback) {
        onSuccessfulSendCallback(tx);
      }

      listenForTransaction(
        this.program.provider.connection,
        tx,
        confirmationLevel,
        onSuccessfulConfirmCallback,
        onErrorCallback,
      );
    } catch (err) {
      if (onErrorCallback) {
        onErrorCallback(err);
      } else {
        console.error(err);
      }
    }
  }

  async withdrawRewardTokensTx(
    owner: PublicKey,
    amount: number,
    tokenMintPubkey?: PublicKey,
  ): Promise<Transaction> {
    var rewardTokenPubkey: PublicKey | null;
    if (!tokenMintPubkey) {
      const rewardTokenHouseIdx = this.rewardHouseTokenIdx;
      rewardTokenPubkey = this.rewardTokenPubkey;
      if (rewardTokenHouseIdx == null || rewardTokenPubkey == null) {
        throw new Error("The platform does not have a reward token configured");
      }
    } else {
      rewardTokenPubkey = tokenMintPubkey;
    }
    const oraclePubkey =
      this.house.getTokenConfigAndStatistics(rewardTokenPubkey)?.oracle;
    const tokenAccountPubkey =
      await this.house.derivePlayerAssociatedTokenAccount(
        owner,
        rewardTokenPubkey,
      );
    const houseTokenVaultPubkey =
      await this.house.deriveHouseTokenVault(rewardTokenPubkey);
    const houseTokenPubkey =
      await this.house.deriveHouseTokenAccountPubkey(rewardTokenPubkey);
    const permissionPubkey = this.derivePlatformPermissionAccountPubkey(owner);

    return await this.program.methods
      .platformWithdrawRewardTokens({
        amount: new anchor.BN(amount),
      })
      .accounts({
        authority: owner,
        authorityPermission: permissionPubkey,
        house: this.house.publicKey,
        houseToken: houseTokenPubkey,
        platform: this.publicKey,
        tokenMint: rewardTokenPubkey,
        tokenAccountOrWallet: tokenAccountPubkey,
        vault: houseTokenVaultPubkey,
        oracle: oraclePubkey,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: anchor.web3.SystemProgram.programId,
      })
      .transaction();
  }

  async awardXp(
    playerPubkey: PublicKey,
    xpToAward: number,
    confirmationLevel: anchor.web3.Commitment = "processed",
    onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
    onSuccessfulConfirmCallback?: Function, // callbackFn(txnHash);
    onErrorCallback?: Function, // callbackFn(err);
  ) {
    try {
      const permissionPubkey = this.derivePlatformPermissionAccountPubkey(
        this.program.provider.publicKey,
      );
      const tx = await this.program.methods
        .platformAwardPlayerXp({
          xpToAward: new anchor.BN(xpToAward),
        })
        .accounts({
          authority: this.program.provider.publicKey,
          authorityPermission: permissionPubkey,
          house: this.house.publicKey,
          platform: this.publicKey,
          player: playerPubkey,
        })
        .signers([])
        .rpc();

      if (onSuccessfulSendCallback) {
        onSuccessfulSendCallback(tx);
      }

      listenForTransaction(
        this.program.provider.connection,
        tx,
        confirmationLevel,
        onSuccessfulConfirmCallback,
        onErrorCallback,
      );
    } catch (err) {
      if (onErrorCallback) {
        onErrorCallback(err);
      } else {
        console.error(err);
      }
    }
  }

  async awardXpTx(
    owner: PublicKey,
    playerPubkey: PublicKey,
    xpToAward: number,
  ): Promise<Transaction> {
    const permissionPubkey = this.derivePlatformPermissionAccountPubkey(owner);

    return await this.program.methods
      .platformAwardPlayerXp({
        xpToAward: new anchor.BN(xpToAward),
      })
      .accounts({
        authority: owner,
        authorityPermission: permissionPubkey,
        house: this.house.publicKey,
        platform: this.publicKey,
        player: playerPubkey,
      })
      .transaction();
  }

  async awardRakebackBoostTx(
    owner: PublicKey,
    playerPubkey: PublicKey,
    boostedRate: number,
    boostedUntil: Date,
  ) {
    const permissionPubkey = this.derivePlatformPermissionAccountPubkey(owner);
    return await this.program.methods
      .platformAwardPlayerRakebackBoost({
        boostedRatePerThousand: Math.floor(boostedRate * 1000),
        until: new anchor.BN(Number(boostedUntil) / 1000),
      })
      .accounts({
        authority: this.program.provider.publicKey,
        authorityPermission: permissionPubkey,
        house: this.house.publicKey,
        platform: this.publicKey,
        player: playerPubkey,
      })
      .transaction();
  }

  async awardRakebackBoost(
    playerPubkey: PublicKey,
    boostedRate: number,
    boostedUntil: Date,
    confirmationLevel: anchor.web3.Commitment = "processed",
    onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
    onSuccessfulConfirmCallback?: Function, // callbackFn(txnHash);
    onErrorCallback?: Function, // callbackFn(err);
  ) {
    try {
      const permissionPubkey = this.derivePlatformPermissionAccountPubkey(
        this.program.provider.publicKey,
      );
      const tx = await this.program.methods
        .platformAwardPlayerRakebackBoost({
          boostedRatePerThousand: Math.floor(boostedRate * 1000),
          until: new anchor.BN(Number(boostedUntil) / 1000),
        })
        .accounts({
          authority: this.program.provider.publicKey,
          authorityPermission: permissionPubkey,
          house: this.house.publicKey,
          platform: this.publicKey,
          player: playerPubkey,
        })
        .signers([])
        .rpc();

      if (onSuccessfulSendCallback) {
        onSuccessfulSendCallback(tx);
      }

      listenForTransaction(
        this.program.provider.connection,
        tx,
        confirmationLevel,
        onSuccessfulConfirmCallback,
        onErrorCallback,
      );
    } catch (err) {
      if (onErrorCallback) {
        onErrorCallback(err);
      } else {
        console.error(err);
      }
    }
  }

  async enhanceReferralSchemeTx(
    signer: PublicKey,
    referralPubkey: PublicKey,
    enhancedRate: number,
    enhancedAccrualExpiryDate: Date,
    defaultRate: number,
    accrualExpiryDate: Date,
    minReferredPlayersToCollect: number,
    minReferredWagerValueToCollect: number,
    upFrontClaim: number,
    daySpreadForClaimRemainder: number,
  ): Promise<Transaction> {
    const permissionPubkey = this.derivePlatformPermissionAccountPubkey(signer);
    return await this.program.methods
      .platformEnhanceReferral({
        enhancedRatePerThousand: Math.floor(enhancedRate * 1000),
        enhancedAccrualExpiryDate: new anchor.BN(
          Number(enhancedAccrualExpiryDate) / 1000,
        ),
        defaultRatePerThousand: Math.floor(defaultRate * 1000),
        accrualExpiryDate: new anchor.BN(Number(accrualExpiryDate) / 1000),
        minReferredPlayersToCollect: new anchor.BN(minReferredPlayersToCollect),
        minReferredWagerValueToCollect: new anchor.BN(
          minReferredWagerValueToCollect,
        ),
        upFrontClaimPerThousand: Math.floor(upFrontClaim * 1000),
        daySpreadForClaimRemainder: daySpreadForClaimRemainder,
      })
      .accounts({
        authority: signer,
        authorityPermission: permissionPubkey,
        house: this.house.publicKey,
        platform: this.publicKey,
        referral: referralPubkey,
      })
      .transaction();
  }

  async enhanceReferralScheme(
    referralPubkey: PublicKey,
    enhancedRate: number,
    enhancedAccrualExpiryDate: Date,
    defaultRate: number,
    accrualExpiryDate: Date,
    minReferredPlayersToCollect: number,
    minReferredWagerValueToCollect: number,
    upFrontClaim: number,
    daySpreadForClaimRemainder: number,
    confirmationLevel: anchor.web3.Commitment = "processed",
    onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
    onSuccessfulConfirmCallback?: Function, // callbackFn(txnHash);
    onErrorCallback?: Function, // callbackFn(err);
  ) {
    try {
      const permissionPubkey = this.derivePlatformPermissionAccountPubkey(
        this.program.provider.publicKey,
      );
      const tx = await this.program.methods
        .platformEnhanceReferral({
          enhancedRatePerThousand: Math.floor(enhancedRate * 1000),
          enhancedAccrualExpiryDate: new anchor.BN(
            Number(enhancedAccrualExpiryDate) / 1000,
          ),
          defaultRatePerThousand: Math.floor(defaultRate * 1000),
          accrualExpiryDate: new anchor.BN(Number(accrualExpiryDate) / 1000),
          minReferredPlayersToCollect: new anchor.BN(
            minReferredPlayersToCollect,
          ),
          minReferredWagerValueToCollect: new anchor.BN(
            minReferredWagerValueToCollect,
          ),
          upFrontClaimPerThousand: Math.floor(upFrontClaim * 1000),
          daySpreadForClaimRemainder: daySpreadForClaimRemainder,
        })
        .accounts({
          authority: this.program.provider.publicKey,
          authorityPermission: permissionPubkey,
          house: this.house.publicKey,
          platform: this.publicKey,
          referral: referralPubkey,
        })
        .signers([])
        .rpc();

      if (onSuccessfulSendCallback) {
        onSuccessfulSendCallback(tx);
      }

      listenForTransaction(
        this.program.provider.connection,
        tx,
        confirmationLevel,
        onSuccessfulConfirmCallback,
        onErrorCallback,
      );
    } catch (err) {
      if (onErrorCallback) {
        onErrorCallback(err);
      } else {
        console.error(err);
      }
    }
  }

  async close(
    confirmationLevel: anchor.web3.Commitment = "processed",
    onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
    onSuccessfulConfirmCallback?: Function, // callbackFn(txnHash);
    onErrorCallback?: Function, // callbackFn(err);
  ) {
    try {
      const tx = await this.program.methods
        .closePlatform({})
        .accounts({
          authority: this.program.provider.publicKey,
          platform: this.publicKey,
          systemProgram: anchor.web3.SystemProgram.programId,
        })
        .signers([])
        .rpc();

      if (onSuccessfulSendCallback) {
        onSuccessfulSendCallback(tx);
      }

      listenForTransaction(
        this.program.provider.connection,
        tx,
        confirmationLevel,
        onSuccessfulConfirmCallback,
        onErrorCallback,
      );
    } catch (err) {
      if (onErrorCallback) {
        onErrorCallback(err);
      } else {
        console.error(err);
      }
    }
  }

  static derivePlatforRanksPubkey(
    platformPubkey: PublicKey,
    programId: PublicKey,
  ): PublicKey {
    const [pk, _] = PublicKey.findProgramAddressSync(
      [
        anchor.utils.bytes.utf8.encode("platform_ranks"),
        platformPubkey.toBuffer(),
      ],
      programId,
    );
    return pk;
  }

  derivePlatformRanksPubkey(): PublicKey {
    return Platform.derivePlatforRanksPubkey(
      this.publicKey,
      this.program.programId,
    );
  }

  async loadPlatformRanks(): Promise<PlatformRanks> {
    return await PlatformRanks.load(
      this,
      this.derivePlatformRanksPubkey(),
      "processed",
    );
  }
}
