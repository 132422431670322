import { createContext, useEffect, useMemo, useRef, useState } from "react";
import { Connection } from "@solana/web3.js";
import { NetworkType, defaultNetworkType } from "../types/chain";
import { defaultNetworkUrl } from "../utils/chain/network";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { ENV_NAME } from "../utils/env/env";
import { BLOCKHASH_COMMITMENT } from "../utils/solana/rpc";

export interface IRecentBlockhash {
  blockhash: string;
  lastValidBlockHeight: number;
}

export interface INetworkContext {
  network: NetworkType;
  networkUrl: string;
  setNetworkUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
  client: Connection | undefined;
  recentBlockhash: IRecentBlockhash;
  networkCounter: number;
}

export const NetworkContext = createContext<INetworkContext>(
  {} as INetworkContext,
);

interface Props {
  children: any;
}

export const NetworkProvider = ({ children }: Props) => {
  const [network, setNetwork] = useState<NetworkType>(defaultNetworkType);
  const [networkUrl, setNetworkUrl] = useLocalStorage(
    `rpc-endpoint-${ENV_NAME}`,
    defaultNetworkUrl,
  );

  const client = useMemo(() => {
    try {
      return new Connection(networkUrl, {
        commitment: "processed",
      });
    } catch (e) {
      console.warn("Issue loading the client", e);
    }
  }, [networkUrl]);
  const [recentBlockhash, setRecentBlockhash] = useState<IRecentBlockhash>();

  const intervalRef = useRef<number>();
  const clientRef = useRef<Connection>();
  const counterRef = useRef<number>(0);
  const [counter, setCounter] = useState<number>();

  useEffect(() => {
    async function loadRecentBlockhash() {
      try {
        // LOAD RECENT BLOCKHASH CONFIRMED/FINALIZED (PROCESSED CAN BE DROPPED...)
        const recentHash =
          await clientRef.current?.getLatestBlockhash(BLOCKHASH_COMMITMENT);
        setRecentBlockhash(recentHash);

        counterRef.current += 1;
        setCounter(counterRef.current);
      } catch (err) {
        console.warn("Error loading blockhash", err);
      }
    }

    if (client != null) {
      clientRef.current = client;
      loadRecentBlockhash();

      if (intervalRef.current != null) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }

      intervalRef.current = setInterval(() => {
        loadRecentBlockhash();
      }, 10000);
    }

    return () => {
      if (intervalRef.current != null) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    };
  }, [client]);

  return (
    <NetworkContext.Provider
      value={useMemo(
        () => ({
          network: network,
          networkUrl: networkUrl,
          client: client,
          recentBlockhash: recentBlockhash,
          networkCounter: counter,
          setNetworkUrl: setNetworkUrl,
        }),
        [network, networkUrl, client, recentBlockhash, counter, setNetworkUrl],
      )}
    >
      {children}
    </NetworkContext.Provider>
  );
};
