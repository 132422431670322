import { PublicKey } from "@solana/web3.js";

import translations from "../../config/translation.json";
import game_list from "../../config/game_list.json";
import token_info from "../../config/token_info.json";
import supported_translations from "../../config/supported_translations.json";

import { AverLanguage } from "../../types/language";
import { ICasinoToken } from "../../types/token";
import { NetworkType, defaultNetwork } from "../chain/network";
import { IPlatformGame } from "../../types/game";

export const supportedLanguages = (language?: AverLanguage) => {
  if (!language) return supported_translations;
  return supported_translations.find((lang) => lang.code === language);
};

export const translateSequence = (
  sequence: string,
  language: AverLanguage,
): string => {
  try {
    return translations[sequence.toUpperCase()][language.toUpperCase()];
  } catch (e) {
    console.warn(
      `Issue retrieving translation for ${sequence} in ${language}.`,
      e,
    );
    return sequence;
  }
};

export const getPlatformTokens = (environment: NetworkType): ICasinoToken[] => {
  try {
    return token_info[environment];
  } catch (e) {
    console.warn(`Error loading the platform tokens for ${environment}.`, e);
    return [];
  }
};

export const getPlatformTokenMetaByPubkey = (): Map<string, ICasinoToken> => {
  const tokens = getPlatformTokens(defaultNetwork);
  const metaByPubkey = new Map<string, ICasinoToken>();
  tokens.forEach((token) => {
    metaByPubkey.set(token.pubkey, token);
  });

  return metaByPubkey;
};

export const getPlatformGames = (): IPlatformGame[] => {
  try {
    return game_list[defaultNetwork].sort((a, b) => a.sortOrder - b.sortOrder);
  } catch (e) {
    console.warn(`Error loading the platform games.`, e);
    return [];
  }
};

interface IPeriodsBeginningTimestamps {
  beginningOfMonth: number;
  beginningOfDay: number;
  beginningOfWeek: number;
}
export const getTimestampsOfBeginningOfPeriods =
  (): IPeriodsBeginningTimestamps => {
    const currentDate = new Date();

    const beginningOfMonth = new Date(currentDate);
    beginningOfMonth.setUTCDate(1);
    beginningOfMonth.setUTCHours(0, 0, 0, 0);
    const beginningOfMonthTimestamp = beginningOfMonth.getTime();

    const beginningOfWeek = new Date(currentDate);
    const diff = (currentDate.getUTCDay() + 6) % 7;
    beginningOfWeek.setUTCDate(currentDate.getUTCDate() - diff);
    beginningOfWeek.setUTCHours(0, 0, 0, 0);
    const beginningOfWeekTimestamp = beginningOfWeek.getTime();

    currentDate.setUTCHours(0, 0, 0, 0);
    const beginningOfDayTimestamp = currentDate.getTime();

    return {
      beginningOfMonth: beginningOfMonthTimestamp,
      beginningOfWeek: beginningOfWeekTimestamp,
      beginningOfDay: beginningOfDayTimestamp,
    };
  };

export const getBaseDecimals = () => {
  const tokens = getPlatformTokens(defaultNetwork);
  const baseToken = tokens.find((token) => {
    return Boolean(token.isBase);
  });

  return baseToken?.decimals || 6;
};

export const getTokenMetaByMint = (
  tokenMintPubkey: PublicKey,
): ICasinoToken | undefined =>
  getPlatformTokens(defaultNetwork).find(
    (token) => token.pubkey === tokenMintPubkey?.toString?.(),
  );
