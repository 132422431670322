import React, { FC, LabelHTMLAttributes, ReactNode } from "react";

export interface FormItemProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  label?: ReactNode | string;
  rightLabel?: ReactNode | string;
  className?: string;
  error?: string;
  subTitle?: string;
}

const FormItem: FC<FormItemProps> = ({
  label,
  rightLabel,
  children,
  className,
  error,
  subTitle,
  ...props
}) => {
  return (
    <div className={`flex flex-col gap-1.5 text-sm [&>*]:w-full ${className}`}>
      {label && (
        <label
          className="flex justify-between text-[100%] text-gray-300"
          {...props}
        >
          <span className="flex items-center">{label}</span>
          <span className="flex items-center">{rightLabel}</span>
        </label>
      )}
      {children}
      {subTitle && !error && (
        <div className="text-sm font-normal leading-none text-gray-400">
          {subTitle}
        </div>
      )}

      {error && (
        <div className="text-sm font-normal leading-none text-error">
          {error}
        </div>
      )}
    </div>
  );
};

export default FormItem;
